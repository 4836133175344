<template>
  <div :class="$style.expand">
    <a-spin :spinning="loading">
      <div v-for="item in expandList" :key="item.productId">
        <div>
          <span>{{ item.productName }}</span>
          <span>{{ getStr1 }}</span>
          <span :class="$style.primaryColor">
            {{ formatNumber(item, 1) }}
          </span>
          <span>{{ getStr2 }}</span>
          <span>（含</span>
          <span :class="$style.primaryColor">
            {{ formatNumber(item, 2) }}
          </span>
          <span>
            <span>{{ getStr2 }}</span>
            <span>免费</span>
            <span>{{ getStr3 }}）</span>
          </span>
        </div>
        <div v-if="item.expands.length" :class="$style.box">
          <div v-for="e in item.expands" :key="e.pkId" :class="$style.card">
            <div style="margin-bottom: 15px">
              <span :class="$style.primaryColor">
                {{ changeNumber(e.expandCount) }}
              </span>
              <span>次调用</span>
            </div>
            <div
              style="display: flex;align-items: center;justify-content: space-between"
            >
              <div style="display: flex;align-items: center;">
                <div :class="$style.icon">
                  <a-icon type="pay-circle" style="font-size: 20px" />
                </div>
                <span :class="$style.primaryColor">
                  {{ changeNumber(e.price) }}
                </span>
                <span>元</span>
              </div>
              <a-button type="primary" size="small" @click="buy(e)">
                购买
              </a-button>
            </div>
          </div>
        </div>
        <div v-else :class="$style.empty">
          <a-empty :image="simpleImage" />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Empty } from 'ant-design-vue';
import { buyExpendList } from '@/services/iot-platform';
import { uuid } from '@triascloud/utils';

@Component()
export default class ExpandList extends Vue {
  @Prop({ type: Array, default: () => [] }) expandList;
  @Prop({ type: Boolean, default: false }) loading;
  @Prop({ type: String, default: 'CALL_COUNT' }) pageType;

  simpleImage;
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  }
  get getStr1() {
    const o = {
      CALL_COUNT: '调用扩展包，当前可调用',
      STORAGE_TIME: '存储扩展包，当前可存储时长',
    };
    return o[this.pageType];
  }
  get getStr2() {
    const o = {
      CALL_COUNT: '次',
      STORAGE_TIME: '个月',
    };
    return o[this.pageType];
  }
  get getStr3() {
    const o = {
      CALL_COUNT: '调用',
      STORAGE_TIME: '存储',
    };
    return o[this.pageType];
  }
  formatNumber(item, flag) {
    if (this.pageType === 'CALL_COUNT') {
      if (flag === 1) {
        return this.changeNumber(item.callCount);
      } else {
        return this.changeNumber(item.freeCallCount);
      }
    } else {
      if (flag === 1) {
        return this.changeNumber(item.storageTime);
      } else {
        return this.changeNumber(item.freeStorageTime);
      }
    }
  }
  changeNumber(val) {
    return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  async buy(item) {
    try {
      const params = {
        expandList: [
          {
            expandCount: 1,
            expandId: item.pkId,
          },
        ],
        serial: uuid(),
      };
      await buyExpendList(params);
      this.$message.success('购买成功');
      this.$emit('refreshList');
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.expand {
  .primaryColor {
    color: var(--primary);
  }
  .box {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 10px; /* 可选项：设置单元格之间的间隔 */
    margin: 10px 0;
    .card {
      height: 80px;
      border: 1px solid var(--border);
      border-radius: 5px;
      padding: 5px;
      .icon {
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--modal-header-bg);
        border-radius: 50%;
      }
    }
  }
  .empty {
    width: 100%;
    height: 80px;
    display: flex;
  }
  :global {
    .ant-empty-normal {
      margin: 10px 0;
    }
    .ant-btn-sm {
      height: 24px;
      min-width: 50px;
    }
  }
}
</style>
