// * 设备类型
export const DEVICE_TYPE = {
  // * 直连设备
  DIRECT_CONNECTION: {
    text: '直连设备',
    value: 'DIRECT_CONNECTION',
    color: '#2db7f5',
  },
  // * 网关设备
  GATEWAY: {
    text: '网关设备',
    value: 'GATEWAY',
    color: 'color:#00b3b3',
  },
  // * 直连设备
  SUB_GATEWAY: {
    text: '网关子设备',
    value: 'SUB_GATEWAY',
    color: '#c5a763',
  },
};
// * 设备面板
export const DEVICE_BASE_INFO = {
  deviceCode: {
    text: '设备码',
    key: 'deviceCode',
  },
  deviceId: {
    text: '设备ID',
    key: 'deviceId',
  },
  deviceKey: {
    text: '设备密钥',
    key: 'deviceKey',
  },
  deviceType: {
    text: '设备类型',
    key: 'deviceType',
  },
  idxDeviceCode: {
    text: '所属网关',
    key: 'idxDeviceCode',
  },
  productName: {
    text: '产品名称',
    key: 'productName',
  },
  bindTime: {
    text: '绑定时间',
    key: 'bindTime',
  },
  deviceOnLine: {
    text: '在线状态',
    key: 'deviceOnLine',
  },
};

// * 更改EMUM数据显示
export function changeEnumValue(val, other) {
  if (!isValue(val)) return undefined;
  const oth = JSON.parse(other);
  const idx = oth.findIndex(v => String(v.value) === String(val));
  return oth[idx].key;
}

// * 更改BOOL数据显示
export function changeBoolValue(val, other) {
  if (!isValue(val)) return undefined;
  let b = val;
  if (typeof val === 'boolean') {
    b = val ? 0 : 1;
  }
  const oth = JSON.parse(other);
  return oth[b];
}
// * 格式化数组结构体
export function changeData(key) {
  if (!key.length) return [];
  const res = [];
  key.forEach(v => {
    const arr = [];
    v.sonData.forEach(son => {
      const data = {
        ...son.data,
        value: isValue(son.data.value) ? son.data.value : '-',
      };
      arr.push(data);
    });
    res.push(arr);
  });
  return res;
}
export function isValue(val) {
  return val !== null && val !== undefined && val !== '';
}
