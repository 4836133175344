<template>
  <div :class="$style.tips">
    <div>确定移除该设备？</div>
    <div :class="$style.warnText">
      移除后，当前企业将不能再查看和管理该设备！
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class DeleteTips extends Vue {}
</script>

<style lang="less" module>
.tips {
  display: flex;
  align-items: center;
  flex-direction: column;
  .warnText {
    margin-top: 10px;
    color: var(--delete);
  }
}
</style>
