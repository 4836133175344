export const ControlTypeEnum = {
  // 分页卡
  Tabs: 'tabs',
  // 文本框
  Input: 'input',
  // 文本框
  /** @name 当前数据组 */
  CurrentDataSet: 'currentDataSet',
  // 整数框
  Int: 'int',
  // 浮点框
  Float: 'float',
  // 双精度浮点框
  Double: 'double',
  // 日期框
  Date: 'date',
  // 新增数据组
  AddDataSet: 'addDataSet',
  // 同步接口
  SyncInterface: 'syncInterface',
  // 功能名称
  FnName: 'fnName',
  // 功能标识
  FnIdentifier: 'fnIdentifier',
  // 自定义模板（入参和出参）
  CustomParam: 'customParam',
  // 开关（是和否）
  Switch: 'bool',
  // 结构体
  Struct: 'struct',
  // 数组
  Array: 'array',
  // 枚举
  Enum: 'enum',
  // 图片
  Image: 'image',
  // 文件
  File: 'file',
  // DP源
  DataPoint: 'dataPoint',
};

/**
 * @param t 类型
 * @param flag 是类型还是颜色
 * @returns {string|*}
 */
export function formateType(t, flag) {
  const obj = {
    [ControlTypeEnum.Int]: {
      lable: '整数',
      color: '#FF3535',
    },
    int32: {
      lable: '整数',
      color: '#FF3535',
    },
    [ControlTypeEnum.Float]: {
      lable: '浮点',
      color: '#FFC12B',
    },
    [ControlTypeEnum.Double]: {
      lable: '双精度',
      color: '#339DEE',
    },
    [ControlTypeEnum.Input]: {
      lable: '文本',
      color: '#848BE2',
    },
    text: {
      lable: '文本',
      color: '#848BE2',
    },
    [ControlTypeEnum.Date]: {
      lable: '日期',
      color: '#9375CD',
    },
    [ControlTypeEnum.Switch]: {
      lable: '布尔',
      color: '#7987CB',
    },
    [ControlTypeEnum.Struct]: {
      lable: '结构体',
      color: '#4FC4F7',
    },
    [ControlTypeEnum.Array]: {
      lable: '数组',
      color: '#4DD1E2',
    },
    [ControlTypeEnum.Enum]: {
      lable: '枚举',
      color: '#EE9A9B',
    },
    [ControlTypeEnum.Image]: {
      lable: '图片',
      color: '#E57375',
    },
    [ControlTypeEnum.File]: {
      lable: '文件',
      color: '#FEB74B',
    },
    [ControlTypeEnum.DataPoint]: {
      lable: 'DP',
      color: '#EEE42A',
    },
  };
  // DP的子项没有type
  if (!t) {
    return flag === 'type' ? 'DP' : 'var(--font-info)';
  }
  // DP的数据标识的格式
  if (!obj[t]) {
    return flag === 'type' ? t : 'var(--font-info)';
  }
  return flag === 'type' ? obj[t].lable : obj[t].color;
}
export const CONDITIONARR = [
  {
    label: '等于',
    value: 'EQUAL',
  },
  {
    label: '不等于',
    value: 'NOT_EQUAL',
  },
  {
    label: '小于',
    value: 'LESS',
  },
  {
    label: '小于等于',
    value: 'LESS_EQUAL',
  },
  {
    label: '大于',
    value: 'GRATER',
  },
  {
    label: '大于等于',
    value: 'GRATER_EQUAL',
  },
  {
    label: '为空',
    value: 'EMPTY',
  },
  {
    label: '不为空',
    value: 'NOT_EMPTY',
  },
  {
    label: '区间',
    value: 'RANGE',
  },
  {
    label: '包含',
    value: 'CONTAIN',
  },
  {
    label: '不包含',
    value: 'NOT_CONTAIN',
  },
];
