<script>
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { PARAM_STATUS, PARAM_TYPE } from './utils';
import { isNumber } from 'lodash';
@Component()
export default class RenderEnum extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() schemeByType;
  @Prop() checkDefaultValue;
  @Prop({ type: String, default: 'id' }) useKey;

  render() {
    const item = this.syncItem;
    if (isNumber(this.syncStore.data[item[this.useKey]])) {
      this.syncStore.set(
        item[this.useKey],
        `${this.syncStore.data[item[this.useKey]]}`,
      );
    }
    return (
      <a-form-model-item
        class={this.$style.formItem}
        rules={[
          { required: item.required, message: '不能为空', trigger: 'blur' },
        ]}
        prop={item[this.useKey]}
      >
        {item[PARAM_TYPE] === PARAM_STATUS.IN ? (
          <a-row class={this.$style.labelHeader}>
            <a-col span={6}>
              <label class={item.required ? 'ant-form-item-required' : ''}>
                {item.name}
              </label>
            </a-col>
            <a-col span={4}>{item.type}</a-col>
            <a-col span={14}>
              <a-select
                mode={item.isSingle ? 'default' : 'multiple'}
                v-model={this.syncStore.data[item[this.useKey]]}
                placeholder={'请选择'}
              >
                {item.optionList &&
                  item.optionList.map(v => {
                    return (
                      <a-select-option key={v.id} value={v.value}>
                        {v.text}
                      </a-select-option>
                    );
                  })}
              </a-select>
            </a-col>
          </a-row>
        ) : (
          <div>
            <label
              slot="label"
              class={[
                this.$style.label,
                item.required ? 'ant-form-item-required' : '',
              ]}
            >
              <span>{item.name}</span>
              {this.tooltip(item)}
              {this.param(item)}
            </label>
            <a-select
              mode={item.isSingle ? 'default' : 'multiple'}
              v-model={this.syncStore.data[item[this.useKey]]}
              placeholder={'请选择'}
            >
              {item.optionList &&
                item.optionList.map(v => {
                  return (
                    <a-select-option key={v.id} value={`${v.value}`}>
                      {v.text}
                    </a-select-option>
                  );
                })}
            </a-select>
          </div>
        )}
      </a-form-model-item>
    );
  }
}
</script>
<style lang="less" module>
.formItem {
  :global {
    .ant-form-item-required {
      display: flex;
      align-items: center;
    }

    .ant-select {
      width: 100%;
    }
  }
}

.label {
  display: flex;
  align-items: center;
}

.labelHeader {
  height: 50px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border);
}
</style>
