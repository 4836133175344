<template>
  <div :class="$style.detail">
    <div :class="$style.timeSelect">
      <div :class="$style.iconStyle">
        <a-tooltip placement="top">
          <template slot="title">
            <span>列表</span>
          </template>
          <x-icon
            style="margin-right: 10px"
            :class="[
              $style.icon,
              activeIcon === viewEnum.LIST ? $style.activeView : '',
            ]"
            type="tc-icon-table-filled"
            @click="changeView(viewEnum.LIST)"
          />
        </a-tooltip>
        <a-tooltip placement="top">
          <template slot="title">
            <span>图表</span>
          </template>
          <a-icon
            :class="[
              $style.icon,
              activeIcon === viewEnum.CHART ? $style.activeView : '',
            ]"
            type="area-chart"
            @click="changeView(viewEnum.CHART)"
          />
        </a-tooltip>
      </div>
      <div>
        <a-radio-group
          v-model="form.redio"
          button-style="solid"
          @change="changeRedio"
          style="margin-right: 10px"
        >
          <a-radio-button
            v-for="item in daysEnum"
            :value="item.value"
            :key="item.value"
          >
            {{ item.label }}
          </a-radio-button>
        </a-radio-group>
        <a-range-picker
          v-model="form.timeRange"
          :disabled-date="disabledDate"
          :disabled-time="disabledRangeTime"
          :show-time="{
            hideDisabledOptions: true,
          }"
          separator="至"
          format="YYYY-MM-DD HH:mm"
          @ok="val => onTimeSearch(val, 'ok')"
          @change="val => onTimeSearch(val, 'change')"
        />
        <a-button type="primary" style="margin-left: 10px" @click="exportFile">
          导出
        </a-button>
      </div>
    </div>
    <div
      v-if="activeIcon === viewEnum.CHART && showSelect"
      :class="$style.statistic"
    >
      <span>统计方式：</span>
      <a-select
        v-model="form.statistic"
        style="margin: 0 10px"
        @change="changeStatistic"
      >
        <a-select-option
          :value="item.value"
          v-for="item in statisticList"
          :key="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
      <span v-if="showRule">统计规则：</span>
      <a-select
        v-if="showRule"
        v-model="form.rule"
        style="margin: 0 10px"
        @change="changeRule"
      >
        <a-select-option
          :value="item.value"
          v-for="item in ruleList"
          :key="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <a-table
      v-if="activeIcon === viewEnum.LIST"
      :columns="columns"
      :dataSource="tableList"
      :pagination="pagination"
      @change="handlePagination"
      :loading="tableLoading"
    >
      <template slot="value" slot-scope="text">
        <span v-if="paramsData.type === 'enum'">
          {{ changeValue(text) }}
        </span>
        <span v-else-if="paramsData.type === 'date'">
          {{ changeValue(text) }}
        </span>
        <span v-else-if="paramsData.type === 'image'">
          <x-oss-image
            class="x-file-icon"
            size="32"
            :oss-path="text"
            @click="
              () => {
                $preview(text, 'image');
              }
            "
          />
        </span>
        <span v-else-if="paramsData.type === 'file'" :class="$style.fileWrap">
          <x-icon
            :class="$style.icon"
            @click="
              () => {
                download(text, itemName);
              }
            "
            type="tc-icon-file-unknown"
          />
        </span>
        <span
          v-else-if="paramsData.type === 'AS'"
          :class="$style.primarySpan"
          @click="toModel(text)"
        >
          详情
        </span>
        <span v-else :title="text">{{ text }}{{ paramsData.unit }}</span>
      </template>
    </a-table>
    <div v-else>
      <PieChart v-if="showPie" :chartData="pieData" />
      <LineChart
        v-if="showSelect"
        :chartData="chartData"
        :show-legend="true"
        :xInterValType="dateAgg"
        :symbol="getSymbol"
        :ruleType="form.rule"
        :unit="[paramsData.unit]"
      />
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import LineChart from '@/views/iot-platform/device-manage/running-status/line-chart.vue';
import {
  deviceFiledsDetail,
  deviceFiledsDetailAs,
  deviceFiledsDetailChart,
  deviceFiledsDetailChartBE,
  deviceFiledsDetailExport,
  getDeviceFileds,
} from '@/services/iot-platform';
import {
  changeBoolValue,
  changeData,
  changeEnumValue,
} from '@/views/iot-platform/device-manage/platform-enum';
import { createModal } from '@triascloud/x-components';
import InnerModel from '@/views/iot-platform/device-manage/inner-model.vue';
import { download } from '@triascloud/utils';
import PieChart from '@/views/iot-platform/device-manage/running-status/pie-chart.vue';

@Component({
  methods: { download },
  components: {
    LineChart,
    InnerModel,
    PieChart,
  },
})
export default class CardDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) paramsData;

  form = {
    redio: 'SEVEN_DAY',
    timeRange: [],
    statistic: 'REAL',
    rule: 'MIN',
  };
  // * 时间枚举
  daysEnum = {
    // * 今天
    CURRENT_DAYS: {
      label: '今天',
      value: 'TODAY',
    },
    // * 近七天
    SEVEN_DAYS: {
      label: '近七天',
      value: 'SEVEN_DAY',
    },
    // * 近30天
    THIRTY_DAYS: {
      label: '近30天',
      value: 'THIRTY_DAY',
    },
  };
  // * 统计方式
  statisticEnum = {
    // * 实际值
    REAL: {
      label: '实际值',
      value: 'REAL',
    },
    // * 按分钟
    MINUTE: {
      label: '按分钟',
      value: 'MINUTE',
    },
    // * 按小时
    HOUR: {
      label: '按小时',
      value: 'HOUR',
    },
    // * 按天
    DAY: {
      label: '按天',
      value: 'DAY',
    },
  };
  // * 统计规则
  ruleEnum = {
    // * 最小值
    MIN: {
      label: '最小值',
      value: 'MIN',
    },
    // * 最大值
    MAX: {
      label: '最大值',
      value: 'MAX',
    },
    // * 平均值
    AVERAGE: {
      label: '平均值',
      value: 'AVG',
    },
    // * 计数
    COUNT: {
      label: '计数',
      value: 'COUNT',
    },
  };
  // * 列表 or chart
  viewEnum = {
    // * 列表
    LIST: 'list',
    // * 图表
    CHART: 'chart',
  };

  /** @description 折线图 */
  get showSelect() {
    return (
      ['int32', 'int', 'double', 'float'].indexOf(this.paramsData.type) > -1
    );
  }

  /** @description 饼图 */
  get showPie() {
    return ['bool', 'enum'].indexOf(this.paramsData.type) > -1;
  }
  defalutRangeTime = 1;
  async getFieds() {
    try {
      const { totalStorageTime } = await getDeviceFileds(
        this.paramsData.devicePkId,
      );
      this.defalutRangeTime = totalStorageTime;
    } catch {
      return false;
    }
  }
  get statisticList() {
    return Object.values(this.statisticEnum);
  }
  get ruleList() {
    return Object.values(this.ruleEnum);
  }
  get showRule() {
    return this.form.statistic !== this.statisticEnum.REAL.value;
  }
  activeIcon = this.viewEnum.LIST;
  changeView(val) {
    this.activeIcon = val;
    if (val === this.viewEnum.CHART) {
      this.getChartData();
    } else {
      this.getTableList();
    }
  }

  changeValue(val) {
    const type = this.paramsData.type;
    if (type === 'enum') {
      return changeEnumValue(val, this.paramsData.other);
    } else if (type === 'bool') {
      return changeBoolValue(val, this.paramsData.other);
    } else if (type === 'date') {
      return this.formatTime(val);
    }
  }
  changeStatistic(val) {
    this.form.statistic = val;
    if (val !== this.statisticEnum.REAL.value) {
      this.dateAgg = val.toLowerCase();
    } else {
      this.form.rule = this.ruleEnum.MIN.value;
      this.dateAgg = 'minute';
    }
    this.getChartData();
  }
  changeRule(val) {
    this.form.rule = val;
    this.getChartData();
  }
  formatTime(val) {
    return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
  }

  changeRedio(val) {
    this.form.redio = val.target.value;
    if (this.activeIcon === this.viewEnum.LIST) {
      this.pagination.current = 1;
      this.getTableList();
    } else {
      this.getChartData();
    }
  }

  timeRange = {
    startTime: '',
    endTime: '',
  };
  onTimeSearch(val, flag) {
    if (flag === 'ok') {
      this.form.timeRange = val;
      const s = this.$moment(val[0]).valueOf();
      const e = this.$moment(val[1]).valueOf();
      this.timeRange = {
        startTime: s,
        endTime: e,
      };
      this.pagination.current = 1;
      this.getTableList();
    } else {
      if (val.length === 0) {
        this.timeRange = {
          startTime: '',
          endTime: '',
        };
        this.pagination.current = 1;
        this.getTableList();
      }
    }
  }

  disabledDate(current) {
    const m = this.defalutRangeTime || 1;
    return (
      current < this.$moment().subtract(m, 'months') || current > this.$moment()
    );
  }

  toModel(key) {
    const arr = changeData(key[0].sonData);
    const modal = createModal(
      () => <InnerModel modelData={arr} onClose={() => modal.handleClose()} />,
      {
        width: '1000px',
        title: this.paramsData.name,
      },
    );
  }

  range(start, end) {
    const result = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  }
  disabledRangeTime() {
    const time = this.$moment()
      .format('HH:mm')
      .split(':');
    return {
      disabledHours: () => this.range(time[0], 23),
      disabledMinutes: () => this.range(time[1], 59),
    };
  }
  dateAgg = 'minute';
  getSymbol = 'none';
  chartData = [];
  pieData = [];
  async getChartData() {
    const params = {
      devicePkId: this.paramsData.devicePkId,
      identifier: this.paramsData.identifier,
      themeId: this.paramsData.themeId,
      timeFilterEnum: this.form.redio,
    };
    if (this.timeRange.startTime) {
      params['startTime'] = this.timeRange.startTime;
      params['endTime'] = this.timeRange.endTime;
    }
    if (this.showSelect) {
      if (this.form.statistic !== this.statisticEnum.REAL.value) {
        params['mongoTimeFormatEnum'] = this.form.statistic;
        params['mathOperationEnum'] = this.form.rule;
      }
      params['isDesc'] = false;
      try {
        const res = await deviceFiledsDetailChart(params);
        this.chartData = [
          {
            dateAgg: this.form.statistic,
            time: res.map(v => v.dataTime),
            name: this.paramsData.name,
            data: res.map(v => v.value),
          },
        ];
        this.getSymbol = res.length > 1 ? 'none' : 'circle';
      } catch {
        return false;
      }
    } else if (this.showPie) {
      params['dataType'] = this.paramsData.type.toUpperCase();
      try {
        const res = await deviceFiledsDetailChartBE(params);
        const arr = [];
        res.forEach(v => {
          const o = {
            name: this.changeValue(v.value),
            value: v.count,
          };
          arr.push(o);
        });
        this.pieData = arr;
      } catch {
        return false;
      }
    }
  }
  mounted() {
    this.getTableList();
    this.getFieds();
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: '序号',
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '创建时间',
        dataIndex: 'dataTime',
      },
      {
        align: 'left',
        title: this.paramsData.name,
        dataIndex: 'value',
        ellipsis: true,
        scopedSlots: { customRender: 'value' },
      },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
  tableLoading = false;
  tableList = [];
  async getTableList() {
    const params = {
      devicePkId: this.paramsData.devicePkId,
      identifier: this.paramsData.identifier,
      themeId: this.paramsData.themeId,
      timeFilterEnum: this.form.redio,
      isDesc: true,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.timeRange.startTime) {
      params['startTime'] = this.timeRange.startTime;
      params['endTime'] = this.timeRange.endTime;
    }
    try {
      this.tableLoading = true;
      let res;
      if (this.paramsData.type === 'AS') {
        res = await deviceFiledsDetailAs(params);
      } else {
        res = await deviceFiledsDetail(params);
      }
      const { records, total, size } = res;
      this.tableLoading = false;
      this.pagination.total = total;
      this.tableList = records;
      this.pagination.pageSize = size;
    } catch {
      this.tableLoading = false;
      return false;
    }
  }
  async exportFile() {
    const params = {
      devicePkId: this.paramsData.devicePkId,
      identifier: this.paramsData.identifier,
      themeId: this.paramsData.themeId,
      isDesc: false,
    };
    if (this.timeRange.startTime) {
      params['startTime'] = this.timeRange.startTime;
      params['endTime'] = this.timeRange.endTime;
    }
    try {
      await deviceFiledsDetailExport(params);
      this.$message.success('导出成功');
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.detail {
  .timeSelect {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .iconStyle {
      width: 90px;
      display: flex;
      .icon {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--modal-header-bg);
        border-radius: 5px;
        font-size: 16px;
        color: var(--font);
        cursor: pointer;
        &:hover {
          background: var(--primary);
          color: #fff;
        }
      }
      .activeView {
        background: var(--primary);
        color: #fff;
      }
    }
  }
  .statistic {
    margin: 10px 0;
  }
  .fileWrap {
    display: flex;
    .icon {
      font-size: 32px;
    }
  }
  .primarySpan {
    color: var(--primary);
    cursor: pointer;
  }
  :global {
    .ant-table-wrapper {
      height: auto;
    }
  }
}
</style>
