<template>
  <div :class="$style.event">
    <div :class="$style.header">
      <div>
        <a-button-group v-if="list && list.length > 1">
          <a-button
            v-for="item in list"
            :key="item.id"
            @click="handleClick(item)"
            :type="item.id === activeKey ? 'primary' : ''"
          >
            {{ item.eventName }}
          </a-button>
        </a-button-group>
      </div>
      <div>
        <a-select
          v-if="filterObj.filterFieldOther"
          v-model="eventForm.select"
          allowClear
          placeholder="请选择"
          style="width: 150px;margin-left: 10px;"
          @change="selectEventEnum"
        >
          <a-select-option
            v-for="item in eventEnum"
            :value="item.value"
            :key="item.value"
          >
            {{ item.key }}
          </a-select-option>
        </a-select>
        <a-range-picker
          v-if="filterObj.filterTime"
          v-model="eventForm.timeRange"
          show-time
          format="yyyy-MM-DD HH:mm"
          separator="至"
          style="width: 325px;margin-left: 10px;"
          @ok="val => onTimeSearch(val, 'ok')"
          @change="val => onTimeSearch(val, 'change')"
        />
        <a-button
          style="margin-left: 10px;"
          type="primary"
          @click="exportEvent"
        >
          导出
        </a-button>
      </div>
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="scrollObj"
      :row-key="record => record.pkId"
      :loading="tableLoading"
      @change="handlePagination"
    />
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { deviceEventExport, getDeviceEvent } from '@/services/iot-platform';
import InnerModel from '@/views/iot-platform/device-manage/inner-model.vue';
import { createModal } from '@triascloud/x-components';
import {
  changeBoolValue,
  changeData,
  changeEnumValue,
  isValue,
} from '@/views/iot-platform/device-manage/platform-enum';
import { download } from '@triascloud/utils';
import { isNumber } from 'lodash';

@Component({
  components: {
    InnerModel,
  },
})
export default class ReportEvent extends Vue {
  @Prop({ type: String, default: '' }) devicePkId;
  @Prop({ type: String, default: '' }) refreshFlag;
  @Prop({ type: Object, default: () => {} }) filterObj;
  @Prop({ type: Array, default: () => [] }) list;

  mounted() {
    if (this.list && this.list.length > 0) {
      this.activeKey = this.list[0].id;
    }
    this.getList();
  }

  activeKey = '';
  handleClick(item) {
    this.activeKey = item.id;
    this.pagination.current = 1;
    this.getList();
  }

  @Watch('refreshFlag')
  changeRefreshFlag(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.eventForm.select = undefined;
      this.eventForm.timeRange = undefined;
      this.pagination.current = 1;
      this.getList();
    }
  }

  eventForm = {
    select: undefined,
    timeRange: undefined,
  };
  selectEventEnum(val) {
    this.eventForm.select = val;
    this.getList();
  }

  get eventEnum() {
    const other = this.filterObj.filterFieldOther;
    return other ? JSON.parse(this.filterObj.filterFieldOther) : [];
  }

  timeRange = {
    startTime: '',
    endTime: '',
  };
  onTimeSearch(val, flag) {
    if (flag === 'ok') {
      this.eventForm.timeRange = val;
      const s = this.$moment(val[0]).valueOf();
      const e = this.$moment(val[1]).valueOf();
      this.timeRange = {
        startTime: s,
        endTime: e,
      };
      this.pagination.current = 1;
      this.getList();
    } else {
      if (val.length === 0) {
        this.timeRange = {
          startTime: '',
          endTime: '',
        };
        this.pagination.current = 1;
        this.getList();
      }
    }
  }

  columns = [];
  tableData = [];
  pagination = {
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    showTotal: total => this.$t('todo.flow.total', { total }),
  };
  tableLoading = false;
  scrollObj = {};
  handlePagination(pagination) {
    this.pagination.current = pagination.current;
    this.getList();
  }
  async getList() {
    try {
      const params = {
        current: this.pagination.current,
        size: this.pagination.pageSize,
        id: this.activeKey,
        devicePkId: this.devicePkId,
      };
      if (isValue(this.eventForm.select)) {
        params['filterFieldValue'] = this.eventForm.select;
      }
      if (this.timeRange.startTime) {
        params['startTime'] = this.timeRange.startTime;
        params['endTime'] = this.timeRange.endTime;
      }
      this.tableData = [];
      const { records, total } = await getDeviceEvent(params);
      if (records && records.length > 0) {
        records.forEach((v, idx) => {
          this.changeColumn(v, idx);
        });
        this.pagination.total = total;
      } else {
        this.columns = [];
      }
    } catch {
      return false;
    }
  }
  formatTime(val, unit) {
    // val是一个字符串的时间戳，"1689391356000"，直接给moment，会报错Invalid date
    if (isNumber(+val)) {
      val = +val;
    }
    return val ? this.$moment(val).format(unit || 'YYYY-MM-DD HH:mm:ss') : '';
  }
  get defaultColumns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => {
          return index + 1;
        },
        title: '序号',
        align: 'center',
        width: 80,
      },
      {
        title: '创建时间',
        dataIndex: 'lastUpdateTime',
        width: 180,
      },
    ];
  }
  changeColumn(row, idx) {
    const columnArr = [];
    const tableArr = [];
    let t = {
      lastUpdateTime: this.formatTime(row.createTime),
    };
    row.groupData.forEach(v => {
      const obj = v.data;
      const sonVO = v.sonData;
      if (!t[obj.identifier]) {
        t[obj.identifier] = {};
      } else {
        t[obj.identifier] = Object.assign({}, t[obj.identifier]);
      }
      if (obj.dataType === 'ARRAY' && obj.childDataType === 'STRUCT') {
        t[obj.identifier] = sonVO;
        if (idx === 0 && this.pagination.current === 1) {
          const o = {
            title: obj.name,
            dataIndex: obj.identifier,
          };
          if (o.title.length > 4) {
            o.width = o.title.length * 20;
          } else {
            o.width = o.title.length * 30;
          }
          o['customRender'] = text => (
            <span
              class={this.$style.primarySpan}
              onClick={e => {
                e.stopPropagation();
                this.openInnerModel(text, o.title);
              }}
            >
              详情
            </span>
          );
          columnArr.push(o);
        }
      } else {
        sonVO.forEach(son => {
          if (obj.identifier === son.data.identifier) {
            t[obj.identifier] = this.changeValueType(son.data);
          } else {
            t[obj.identifier][son.data.identifier] = this.changeValueType(
              son.data,
            );
          }
          if (idx === 0 && this.pagination.current === 1) {
            const o = {
              title: '',
              dataIndex: '',
              ellipsis: true,
            };
            if (obj.identifier === son.data.identifier) {
              o.title = obj.name;
              o.dataIndex = obj.identifier;
            } else {
              o.title = `${obj.name}.${son.data.name}`;
              o.dataIndex = `${obj.identifier}.${son.data.identifier}`;
            }
            if (o.title.length > 4) {
              o.width = o.title.length * 20;
            } else {
              o.width = o.title.length * 30;
            }
            if (son.data.dataType === 'FILE') {
              o['customRender'] = text => (
                <span title={text} class={this.$style.fileWrap}>
                  <x-icon
                    class={this.$style.icon}
                    onClick={() => {
                      download(text, o.title);
                    }}
                    type="tc-icon-file-unknown"
                  />
                </span>
              );
            } else if (son.data.dataType === 'IMAGE') {
              o['customRender'] = text => (
                <span>
                  <x-oss-image
                    class="x-file-icon"
                    size="32"
                    oss-path={text}
                    onClick={() => {
                      this.$preview(text, 'image');
                    }}
                  />
                </span>
              );
            } else {
              o['customRender'] = text => (
                <span title={text}>{this.changeText(text)}</span>
              );
            }

            columnArr.push(o);
          }
        });
      }
    });
    if (idx === 0 && this.pagination.current === 1) {
      this.columns = this.defaultColumns;
      this.columns = this.columns.concat(columnArr);
    }
    tableArr.push(t);
    this.tableData = this.tableData.concat(tableArr);
    const len = this.columns.length;
    if (len > 7) {
      this.scrollObj['x'] = 1000 + 1000 * Math.floor(len / 7);
    }
    this.tableLoading = false;
  }
  openInnerModel(key, name) {
    const arr = changeData(key);
    const modal = createModal(
      () => <InnerModel modelData={arr} onClose={() => modal.handleClose()} />,
      {
        width: '1000px',
        title: name,
      },
    );
  }

  changeText(val) {
    if (typeof val == 'object') {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }
  changeValueType(row) {
    const type = row.dataType.toLowerCase();
    let value = '';
    let unit = row.unit;
    if (type === 'enum') {
      value = changeEnumValue(row.value, row.other);
    } else if (type === 'date') {
      value = this.formatTime(row.value, row.unit);
      unit = '';
    } else if (type === 'bool') {
      value = changeBoolValue(row.value, row.other);
    } else if (type === 'array') {
      value = row.value ? JSON.stringify(row.value) : '-';
    } else {
      value = row.value;
    }
    return isValue(value) ? `${value}${unit}` : `-`;
  }

  async exportEvent() {
    const params = {
      devicePkId: this.devicePkId,
      id: this.activeKey,
    };
    if (this.timeRange.startTime) {
      params['startTime'] = this.timeRange.startTime;
      params['endTime'] = this.timeRange.endTime;
    }
    if (isValue(this.eventForm.select)) {
      params['filterFieldValue'] = this.eventForm.select;
    }
    try {
      await deviceEventExport(params);
      this.$message.success('导出成功');
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.event {
  .header {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
  }
  .primarySpan {
    color: var(--primary);
    cursor: pointer;
  }
  :global {
    .x-file-icon {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .fileWrap {
    display: flex;
    .icon {
      font-size: 32px;
    }
  }
}
</style>
