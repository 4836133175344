<template>
  <div :class="$style.pieChart" ref="pie">
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <EmptyContent />
    </div>
    <div v-else ref="pieChart" style="width: 100%; height: 500px"></div>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import { crossStorageModule } from '@/enum/store';
import * as echarts from 'echarts';

@Component({
  components: { EmptyContent },
})
export default class PieChart extends Vue {
  @Prop({ type: Array, default: () => [] }) chartData;
  @Prop({
    type: Array,
    default: () => [
      '#4FA2FF',
      '#00C56E',
      '#FFCC37',
      '#EF6666',
      '#0065C4',
      '#07B9B9',
      '#3BA272',
      '#EA7CCC',
      '#91CC75',
      '#FAC858',
    ],
  })
  colorArray;

  @crossStorageModule.State('skin') skin;
  @Watch('skin')
  changeSkin() {
    this.initChart();
  }
  mounted() {
    this.initChart();
  }

  @Watch('chartData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal.length) {
      this.isNoData = false;
      this.$nextTick(() => {
        this.initChart();
      });
    } else {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.isNoData = true;
    }
  }

  isNoData = false;
  myChart = null;
  initChart() {
    if (!this.myChart) {
      const el = this.$refs.pieChart;
      if (!el) return;
      this.myChart = echarts.init(el);
    }
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: '{b}:{c}（{d}%）',
      },
      color: this.colorArray,
      legend: {
        type: 'scroll',
        top: 'bottom',
      },
      series: [
        {
          name: 'chart',
          type: 'pie',
          radius: '50%',
          data: this.chartData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'var(--mask)',
            },
          },
          label: {
            show: true,
            formatter: '{b}：{c}（{d}%）',
          },
        },
      ],
    };
    this.myChart.setOption(option, true);
  }
}
</script>

<style lang="less" module>
.pieChart {
  height: calc(100% - 80px);
}
</style>
