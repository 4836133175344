<template>
  <div :class="$style.viewPage">
    <a-spin :spinning="loading">
      <div :class="$style.card">
        <x-oss-image
          basePath="/oss/oss"
          :ossPath="titleObj.headFigure"
          :class="$style.img"
        />
        <div :class="$style.info">
          <div :class="$style.title">
            {{ titleObj.deviceCode }}
          </div>
          <div>
            <span
              v-for="(item, key, idx) in baseInfo"
              :key="idx"
              style="margin-right: 10px"
            >
              <span :class="$style.label"> {{ item.text }}： </span>
              <span>{{ item.value }}</span>
            </span>
          </div>
          <div :class="$style.introduce">
            <span>查看产品介绍</span>
          </div>
        </div>
        <div>
          <a-button
            :class="$style.defaultBtn"
            style="margin-right: 10px"
            @click="cancel"
          >
            返回
          </a-button>
          <a-button type="primary" @click="refreshData">刷新数据</a-button>
        </div>
      </div>
    </a-spin>
    <div :class="$style.borderBar"></div>
    <div :class="[$style.card, $style.tab]">
      <a-tabs :default-active-key="activeKey" @change="changeTab">
        <a-tab-pane key="running-status" tab="运行状态">
          <running-status
            v-if="activeKey === 'running-status'"
            :devicePkId="devicePkId"
            :showTime="filterFields.attributeTimeDisplay"
            :refreshFlag="refreshFlag"
          />
        </a-tab-pane>
        <a-tab-pane key="device-control" tab="设备控制">
          <device-control
            v-if="activeKey === 'device-control'"
            :devicePkId="devicePkId"
          />
        </a-tab-pane>
        <a-tab-pane key="report-event" tab="上报事件">
          <report-event
            v-if="activeKey === 'report-event'"
            :devicePkId="devicePkId"
            :refreshFlag="refreshFlag"
            :filterObj="{
              filterFieldOther: filterFields.filterFieldOther,
              filterTime: filterFields.filterTime,
            }"
            :list="filterFields.eventConfigList"
          />
        </a-tab-pane>
        <a-tab-pane
          key="device-location"
          v-if="
            filterFields.customConfigList &&
              filterFields.customConfigList.length
          "
          :tab="customTxt"
        >
          <device-location
            v-if="activeKey === 'device-location'"
            :devicePkId="devicePkId"
            :custom="filterFields.customConfigList"
          />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import ReportEvent from '@/views/iot-platform/device-manage/report-event/index.vue';
import RunningStatus from '@/views/iot-platform/device-manage/running-status/index.vue';
import DeviceControl from '@/views/iot-platform/device-manage/device-control/index.vue';
import DeviceLocation from '@/views/iot-platform/device-manage/device-location/index.vue';
import { getDeviceDetail, getDeviceFileds } from '@/services/iot-platform';
import {
  DEVICE_BASE_INFO,
  DEVICE_TYPE,
} from '@/views/iot-platform/device-manage/platform-enum';
import { uuid } from '@triascloud/utils';

@Component({
  components: {
    ReportEvent,
    RunningStatus,
    DeviceControl,
    DeviceLocation,
  },
})
export default class ViewPage extends Vue {
  @Prop({ type: String, default: '' }) devicePkId;
  cancel() {
    this.$emit('cancel');
  }
  activeKey = 'running-status';
  changeTab(activeKey) {
    this.activeKey = activeKey;
  }
  mounted() {
    this.getCardData();
    this.getFieds();
  }
  filterFields = {
    attributeTimeDisplay: false,
    filterFieldOther: false,
    filterTime: false,
    customConfigList: [],
    eventConfigList: [],
  };
  refreshFlag = '';
  refreshData() {
    this.refreshFlag = uuid();
  }
  loading = false;
  customTxt = '';
  async getFieds() {
    try {
      this.filterFields = await getDeviceFileds(this.devicePkId);
      if (
        this.filterFields.customConfigList &&
        this.filterFields.customConfigList.length
      ) {
        const data = this.filterFields.customConfigList[0];
        if (data.type === 'DEVICE_POSITION') {
          this.customTxt = '设备定位';
        }
      }
    } catch {
      return false;
    }
  }
  formatTime(val) {
    return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '-';
  }

  baseInfo = {};
  titleObj = {};
  otherKeys(key) {
    return key !== 'headFigure' && key !== 'devicePkId' && key !== 'deviceCode';
  }

  async getCardData() {
    try {
      this.loading = true;
      const res = await getDeviceDetail(this.devicePkId);
      const o = {};
      const o1 = {};
      if (res) {
        for (const key in res) {
          if (this.otherKeys(key)) {
            if (key === 'deviceType') {
              o[key] = {
                text: DEVICE_BASE_INFO[key].text,
                value: DEVICE_TYPE[res[key]].text || '-',
              };
            } else if (key === 'bindTime') {
              o[key] = {
                text: DEVICE_BASE_INFO[key].text,
                value: this.formatTime(res[key]),
              };
            } else if (key === 'deviceOnLine') {
              o[key] = {
                text: DEVICE_BASE_INFO[key].text,
                value: res[key] ? '在线' : '离线',
              };
            } else {
              o[key] = {
                text: DEVICE_BASE_INFO[key].text,
                value: res[key] || '-',
              };
            }
          } else {
            o1[key] = res[key];
          }
        }
        this.baseInfo = o;
        this.titleObj = o1;
        this.loading = false;
      }
    } catch {
      this.loading = false;
      return false;
    }
  }
}
</script>

<style lang="less" module>
.viewPage {
  .card {
    position: relative;
    width: 100%;
    height: 142px;
    background-color: var(--nav-bg);
    border: 1px solid var(--border);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 20px;
    display: flex;

    .img {
      height: 100px;
      width: 100px;
      border-radius: 5px;
    }

    .info {
      position: relative;
      margin-left: 15px;
      flex: 1;
      font-size: 16px;
      .title {
        font-size: 20px;
        margin-bottom: 5px;
      }
      .label {
        color: var(--font-info);
        &:not(:first-child) {
          margin-left: 20px;
        }
      }
      .introduce {
        position: absolute;
        left: 0;
        bottom: 0;
        color: var(--primary);
        cursor: pointer;
      }
    }
    .defaultBtn {
      &:hover {
        color: var(--primary);
        background-color: var(--nav-bg);
        border-color: var(--primary);
      }
    }
  }
  .borderBar {
    width: 100%;
    height: 20px;
    margin-top: -5px;
    background-color: var(--body-bg);
  }
  .tab {
    border-radius: 5px;
    margin-top: -5px;
    height: calc(100vh - 285px);
    border-bottom: none;
    display: block;
    padding: 0 10px 10px;
  }
  :global {
    .ant-tabs-nav-container {
      border-bottom: 1px solid var(--border);
    }
    .ant-tabs-bar {
      margin: 0;
    }
  }
}
</style>
