import request from '../request';

const serviceName = '/iot-platform/tenant/device/api';

/** @description 获取平台API基础设置 */
export function getBasicSettings(deviceId, id) {
  return request(`${serviceName}/basic/${deviceId}/${id}`, {
    method: 'GET',
  });
}
/** @description 获取平台API API设置 */
export function getApiSettings(deviceId, id) {
  return request(`${serviceName}/apiSettings/${deviceId}/${id}`, {
    method: 'GET',
  });
}
/** @description 获取平台API触发设置 */
export function getTriggerSettings(deviceId, id) {
  return request(`${serviceName}/triggerSettings/${deviceId}/${id}`, {
    method: 'GET',
  });
}
/** @description 获取平台API变量列表 */
export function getVariable(data) {
  return request(`${serviceName}/variable`, {
    method: 'GET',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: data,
  });
}
/** @description 设备控制列表 */
export function getEquipmentControl(deviceId) {
  return request(`${serviceName}/equipmentControl/${deviceId}`, {
    method: 'GET',
  });
}

/** @description HTTP调试 */
export function debug(headers, data) {
  return request(`${serviceName}/debug`, {
    method: 'POST',
    body: data,
    headers,
  });
}

/** @description 获取前置API */
export function preInterface(deviceId, id) {
  return request(`${serviceName}/before/${deviceId}/${id}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
  });
}

/** @description 前置接口API */
export function prefixApi(url, data) {
  return request(url, {
    method: 'POST',
    body: data,
  });
}
