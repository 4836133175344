<template>
  <div>
    <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
      <a-form-model-item label="产品一级分类" prop="mainSort">
        <a-select
          placeholder="请选择主分类"
          v-model="form.mainSort"
          allowClear
          :disabled="disabledFlag"
          @change="changeFirst"
        >
          <a-select-option
            v-for="item in fatherData"
            :value="item.pkId"
            :key="item.pkId"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="产品二级分类" prop="subSort">
        <a-select
          placeholder="请选择主分类"
          v-model="form.subSort"
          allowClear
          :disabled="disabledFlag"
          @change="changeSecond"
        >
          <a-select-option
            v-for="item in childrenData"
            :value="item.pkId"
            :key="item.pkId"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        v-if="!disabledFlag"
        label="产品名称"
        prop="deviceName"
      >
        <a-select
          v-if="!disabledFlag"
          placeholder="请选择产品名称"
          v-model="form.deviceName"
          allowClear
        >
          <a-select-option
            v-for="item in productList"
            :value="item.pkId"
            :key="item.pkId"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item v-else label="产品名称" prop="productName">
        <a-input v-model="editData.productName" :disabled="disabledFlag" />
      </a-form-model-item>
      <a-form-model-item label="设备码" prop="deviceCode">
        <a-input
          placeholder="请输入设备码"
          v-model="form.deviceCode"
          :disabled="disabledFlag"
        />
      </a-form-model-item>
      <a-form-model-item label="验证码" prop="checkCode">
        <a-input
          placeholder="请输入验证码"
          v-model="form.checkCode"
          :disabled="disabledFlag"
        />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input
          placeholder="请输入备注"
          v-model="form.remark"
          :maxLength="128"
        />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  addPlatform,
  editDeviceInfo,
  gatCategoryList,
  getProductListById,
} from '@/services/iot-platform';
import { DEVICE_TYPE } from './platform-enum';

@Component({})
export default class PlatformForm extends Vue {
  @Prop({ type: Boolean, default: false }) disabledFlag;
  @Prop({ type: Object, default: () => {} }) editData;

  form = {
    mainSort: undefined,
    subSort: undefined,
    deviceName: undefined,
    deviceCode: '',
    checkCode: '',
    remark: '',
  };
  rules = {
    mainSort: [
      {
        required: true,
        message: '请选择一级产品分类',
      },
    ],
    subSort: [
      {
        required: true,
        message: '请选择二级产品分类',
      },
    ],
    deviceCode: [
      {
        required: true,
        message: '设备码不允许为空',
      },
    ],
    deviceName: [
      {
        required: true,
        message: '设备名称不允许为空',
      },
    ],
    checkCode: [
      {
        required: true,
        message: '验证码不允许为空',
      },
    ],
  };
  DEVICE_TYPE = DEVICE_TYPE;
  mounted() {
    this.getCategoryLists(0);
  }

  childrenData = [];
  changeFirst(val) {
    // this.infoForm.mainSort = val;
    this.form.subSort = undefined;
    this.childrenData = [];
    if (val) {
      this.getCategoryLists(val);
    } else {
      this.getCategoryLists(0);
    }
  }
  productList = [];
  async changeSecond(val) {
    try {
      this.productList = await getProductListById(val);
    } catch {
      return false;
    }
  }
  fatherData = [];
  async getCategoryLists(id = 0) {
    const params = {
      parentId: id === 0 ? id : id,
    };
    try {
      const res = await gatCategoryList(params);
      if (id === 0) {
        this.fatherData = res;
        if (this.editData.pkId) {
          const obj = this.editData;
          this.form.mainSort = obj.mainSort;
          this.childrenData = obj.subSort;
          this.form.subSort = obj.subSort[0].pkId;
          this.form.deviceCode = obj.deviceCode;
          this.form.checkCode = obj.checkCode;
          this.form.remark = obj.remark;
        }
      } else {
        this.childrenData = res;
      }
    } catch {
      return false;
    }
  }
  async getValue() {
    await this.$refs.form.validate();
    try {
      if (!this.disabledFlag) {
        const form = this.form;
        const params = {
          categoryId: form.subSort,
          deviceCode: form.deviceCode,
          key: form.checkCode,
          productId: form.deviceName,
          remark: form.remark,
        };
        await addPlatform(params);
        this.$message.success('添加成功');
      } else {
        const params = {
          deviceId: this.editData.pkId,
          remark: this.form.remark,
        };
        await editDeviceInfo(params);
        this.$message.success('编辑成功');
      }
    } catch (e) {
      return Promise.reject();
    }
    return true;
  }
}
</script>

<style lang="less" module></style>
