<template>
  <section :class="$style.box">
    <div :class="$style.wrap" id="wrap"></div>
    <div :class="$style.form" v-if="isPreviewLocus">
      <a-range-picker
        v-model="form.timeRange"
        :class="$style.range"
        :show-time="{ format: 'YYYY-MM-DD HH:mm' }"
        format="YYYY-MM-DD HH:mm"
        :placeholder="['开始日期', '结束日期']"
        @change="handleSearchTime"
        @ok="addLineByCalendar"
        :disabled="play"
      />
      <a-select
        :class="$style.select"
        v-model="form.speed"
        placeholder="1.0X"
        allow-clear
        @change="handleSelectSpeed"
        :disabled="play"
      >
        <a-select-option
          v-for="(item, index) in speedList"
          :key="index"
          :value="item"
        >
          {{ item }}
        </a-select-option>
      </a-select>
      <a-button
        :class="$style.btn"
        type="primary"
        size="small"
        @click="handlePlay"
      >
        {{ !play ? '播放' : '停止' }}
      </a-button>
      <a-button
        :class="$style.btn"
        icon="sync"
        type="primary"
        size="small"
        @click="reloadLocation"
      >
        刷新定位
      </a-button>
    </div>
    <div :class="$style.form" v-else>
      <a-button
        :class="$style.btn"
        icon="sync"
        type="primary"
        size="small"
        @click="reloadLocation"
      >
        刷新定位
      </a-button>
    </div>
    <div v-if="isPreviewLocus" :class="$style.label">
      时间：{{ time }}；位置：{{ location }}
    </div>
  </section>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'moment';
import {
  createPathSimplifier,
  funAMap,
  INIT_SPEED,
  mouseEvent,
} from '@/views/hat/electron-fence/components/util';
import { previewPosition } from '@/services/iot-platform/index';
/** @name hover弹窗 */
const hoverDialog = msg => {
  return `
    <style>
    .worldWrap {
      background-color: rgba(0, 0, 0, 0.6);
      padding: 6px 10px;
      border-radius: 4px;
      position: relative
    }
    .worldWrap::before {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-top: 10px solid rgba(0, 0, 0, 0.6);
    }
    .worldWrap .txt {
      color: #fff;
      font-size: 14px;
      display: block;
      min-width: 220px;
    }
    .worldWrap .txt + .txt {
      margin-top: 5px;
    }
    </style>
    <section class="worldWrap">
      <span class="txt"
        >经度：${msg.lng}</span
      >
      <span class="txt"
        >维度：${msg.lat}</span
      >
      <span class="txt"
        >最近更新：${msg.time || '-'}</span
      >
    </section>
  `;
};

@Component()
export default class DeviceLocation extends Vue {
  @Prop({ type: String }) devicePkId;
  @Prop({ type: Array, default: () => [] }) custom;

  async mounted() {
    this.initTime();
    await this.previewPosition();
    await this.init();
  }

  destroyed() {
    if (this.pathSimplifier) {
      this.pathSimplifier.setData([]);
    }
  }

  initTime() {
    if (!this.isPreviewLocus) {
      return;
    }
    const fn = t => dayjs(t);
    const startDate = dayjs()
      .startOf('date')
      .format('YYYY-MM-DD HH:mm');
    const endDate = dayjs().format('YYYY-MM-DD HH:mm');
    this.form.timeRange = [fn(startDate), fn(endDate)];
  }

  async reloadLocation() {
    await this.previewPosition(() => {
      this.$message.success('数据刷新成功');
    });
  }

  async previewPosition(callback) {
    try {
      const opt = {
        devicePkId: this.devicePkId,
      };
      if (this.form.timeRange.length) {
        opt['startTime'] = this.form.timeRange[0].format('x');
        opt['endTime'] = this.form.timeRange[1].format('x');
      }
      const data = (await previewPosition(opt)) ?? [];
      if (callback) {
        callback();
      }
      const paths = [];
      const timeList = [];
      data.forEach(v => {
        if (v.point2D) {
          paths.push([v.point2D.x, v.point2D.y]);
          timeList.push(dayjs(v.time).format('YYYY/MM/DD HH:mm:ss'));
        }
      });
      if (this.isPreviewLocus && data.length === 0) {
        this.time = '-';
        this.location = '-';
      }
      this.tracePath = paths;
      this.timeList = timeList;

      if (this.isPreviewLocus && this.map) {
        this.createPath();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  handleSearchTime(date) {
    if (date.length === 0) {
      this.previewPosition();
    }
  }
  addLineByCalendar() {
    if (!this.play) {
      this.stopAnimation();
    }
    this.previewPosition();
  }

  createMarker(position) {
    return new this.AMap.Marker({
      position,
      draggable: true,
      zIndex: 8,
    });
  }

  /** @description 是否显示轨迹 */
  get isPreviewLocus() {
    if (this.custom && this.custom.length) {
      return this.custom[0].isPreviewLocus;
    } else {
      return false;
    }
  }

  AMap = undefined;
  map = undefined;
  center = [];
  time = '-';
  timeList = [];
  async init() {
    const AMap = await funAMap({
      AMapUI: {
        version: '1.1',
        plugins: ['misc/PathSimplifier'],
      },
    });
    this.AMap = AMap;
    const opt = {
      zoom: 16,
    };
    if (!this.isPreviewLocus) {
      this.center = [this.tracePath[0][0], this.tracePath[0][1]];
      this.time = this.timeList[0];
    }
    if (this.center.length) {
      opt['center'] = this.center;
    }
    this.map = new AMap.Map('wrap', opt);

    if (this.center.length) {
      const centerMarker = this.createMarker(this.center);
      const extData = {
        lng: this.center[0],
        lat: this.center[1],
        time: this.time,
      };
      centerMarker.setExtData(extData);
      this.map.add(centerMarker);
      mouseEvent(AMap, centerMarker, this.map, {
        content: hoverDialog(extData),
        offset: new AMap.Pixel(-120, -100),
      });
    }

    if (this.isPreviewLocus) {
      this.createPath();
    }
  }

  navigator = undefined;
  pathSimplifier = undefined;
  location = '-';
  time = '';
  tracePath = [];
  async createPath() {
    if (this.pathSimplifier) {
      this.pathSimplifier.setData([]);
    }
    if (this.tracePath.length > 0) {
      this.time = this.timeList[0];
      this.location = `${this.tracePath[0][0]},${this.tracePath[0][1]}`;
      [this.navigator, this.pathSimplifier] = await createPathSimplifier(
        this.map,
        this.tracePath,
        idx => {
          this.time = this.timeList[idx];
          this.location = `${this.tracePath[idx][0]},${this.tracePath[idx][1]}`;
          if (idx === this.tracePath.length - 1) {
            this.stopAnimation();
            this.play = false;
          }
        },
      );
    }
  }
  startAnimation() {
    this.navigator.start();
  }
  stopAnimation() {
    this.navigator.pause();
  }

  form = {
    timeRange: [],
    speed: '1.0X',
  };
  speedList = [
    '0.1X',
    '0.5X',
    '0.8X',
    '1.0X',
    '1.2X',
    '1.5X',
    '2.0X',
    '5.0X',
    '10X',
    '20X',
  ];
  play = false;
  handlePlay() {
    if (!this.play) {
      this.startAnimation();
      this.play = true;
    } else {
      this.stopAnimation();
      this.play = false;
    }
  }
  handleSelectSpeed() {
    if (!this.form.speed) return;
    const speed = INIT_SPEED * parseFloat(this.form.speed);
    this.navigator.setSpeed(speed);
  }
}
</script>
<style lang="less" module>
.box {
  position: relative;
  height: calc(100vh - 341px);
}
.wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.form {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  .range {
    background-color: #ffffff;
    border-radius: 4px;
    width: 320px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    margin-right: 10px;
    :global {
      .ant-input {
        border: none;
      }
    }
  }
  .select {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    width: 75px;
    margin-right: 10px;
    :global {
      .ant-select-selection {
        border: none;
      }
    }
  }
  .btn {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    height: 32px;
    margin-right: 10px;
  }
}
.label {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 4px 10px;
  border-radius: 4px;
  color: #ffffff;
}
</style>
