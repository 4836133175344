<template>
  <div :class="$style.screenList">
    <a-dropdown
      v-model="dropDownVisible"
      placement="bottomRight"
      :trigger="['click']"
      :overlayClassName="$style.dropdown"
      :getPopupContainer="triggerNode => triggerNode.parentNode"
    >
      <a-button
        type="link"
        icon="filter"
        style="padding: 0"
        @click="e => e.preventDefault()"
      >
        筛选数据{{ getLength }}
      </a-button>
      <div slot="overlay" @click="handleMenuClick">
        <div :class="$style.header">
          <span style="font-size: 14px">筛选</span>
          <a-button type="link" style="padding: 0" @click.stop="cleanScreen">
            <x-icon type="tc-icon-brush" />
            清空
          </a-button>
        </div>
        <div :class="$style.content">
          <div :class="$style.scroll">
            <div :class="$style.logic" v-if="ruleList.length">
              <x-select
                :value="form.logic"
                :data="logicData"
                @input="handleLogicChange"
                size="small"
                style="width: 100%;"
                :dropdownClassName="$style.dropdownLogic"
              />
            </div>
            <div style="flex: 1;overflow: hidden;">
              <a-row :gutter="10">
                <a-col
                  v-for="(item, idx) in ruleList"
                  :key="item.id"
                  :class="$style.select"
                >
                  <a-col :span="8" style="display: flex;align-items: center">
                    <a-select
                      v-model="item.typeId"
                      style="width: 100%;"
                      placeholder="请选择"
                      @change="val => changeCondition(val, idx)"
                    >
                      <a-select-option
                        v-for="t in ruleSelectList"
                        :key="t.id"
                        :value="t.id"
                      >
                        <div
                          style="display: flex;justify-content: space-between;align-items: center;"
                        >
                          <div
                            :title="t.name"
                            style="width: 230px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                          >
                            {{ t.name }}
                          </div>
                          <a-tag
                            v-if="formateType(t.type, 'color')"
                            :color="formateType(t.type, 'color')"
                            style="height: 24px;line-height: 12px;border-radius: 12px"
                          >
                            {{ formateType(t.type, 'type') }}
                          </a-tag>
                        </div>
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="3">
                    <a-select
                      v-model="item.rule"
                      style="width: 100%;"
                      placeholder="请选择"
                      @change="
                        val => {
                          changeRule(val, item);
                        }
                      "
                    >
                      <a-select-option
                        v-for="t in item.ruleSelectList"
                        :key="t.value"
                        :value="t.value"
                      >
                        {{ t.label }}
                      </a-select-option>
                    </a-select>
                  </a-col>
                  <a-col :span="11">
                    <a-date-picker
                      v-if="item.type === 'date' && item.rule !== 'RANGE'"
                      v-model="item.value"
                      show-time
                      format="YYYY-MM-DD HH:mm:ss"
                      style="width: 100%;"
                      placeholder="选择时间"
                      @ok="
                        v => {
                          handleTimeChange(v, item, 'ok');
                        }
                      "
                      @change="
                        v => {
                          handleTimeChange(v, item, 'change');
                        }
                      "
                    />
                    <a-range-picker
                      v-else-if="item.type === 'date' && item.rule === 'RANGE'"
                      v-model="item.value"
                      show-time
                      format="YYYY-MM-DD HH:mm:ss"
                      style="width: 100%;"
                      :placeholder="['开始时间', '结束时间']"
                      @ok="
                        v => {
                          handleTimeChange(v, item, 'ok');
                        }
                      "
                      @change="
                        v => {
                          handleTimeChange(v, item, 'change');
                        }
                      "
                    />
                    <a-switch
                      v-else-if="item.type === 'bool'"
                      v-model="item.value"
                      checked-children="开"
                      un-checked-children="关"
                    />
                    <a-select
                      v-else-if="item.type === 'enum'"
                      placeholder="请选择设备"
                      v-model="item.value"
                      style="width: 100%;"
                    >
                      <a-select-option
                        v-for="e in item.selectList"
                        :value="e.value"
                        :key="e.value"
                      >
                        {{ e.key }}
                      </a-select-option>
                    </a-select>
                    <a-input
                      v-else-if="showInput(item)"
                      v-model="item.value"
                      allowClear
                      placeholder="请输入条件值"
                    />
                    <a-input
                      v-else-if="
                        getIntType(item) &&
                          ['EMPTY', 'NOT_EMPTY'].indexOf(item.rule) === -1 &&
                          item.rule !== 'RANGE'
                      "
                      type="number"
                      v-model="item.value"
                      @blur="validateMin(item)"
                      allowClear
                    />
                    <div
                      :class="$style.rangeValue"
                      v-else-if="
                        getIntType(item) &&
                          ['EMPTY', 'NOT_EMPTY'].indexOf(item.rule) === -1 &&
                          item.rule === 'RANGE'
                      "
                    >
                      <a-input
                        type="number"
                        v-model="item.minValue"
                        @blur="validateMin1(item)"
                        allowClear
                      />
                      <span style="margin: 0 10px">-</span>
                      <a-input
                        type="number"
                        v-model="item.maxValue"
                        @blur="validateMax(item)"
                        allowClear
                      />
                    </div>
                  </a-col>
                  <a-col :span="2">
                    <x-icon
                      type="tc-icon-delete"
                      :class="$style.deleteIcon"
                      @click="deleteRule(idx)"
                    />
                  </a-col>
                </a-col>
              </a-row>
            </div>
          </div>
          <div>
            <a-button
              type="link"
              icon="plus"
              style="padding: 0"
              @click.stop="addRule"
            >
              添加规则
            </a-button>
          </div>
          <div style="text-align: end;margin-top: -20px">
            <a-button type="primary" @click.stop="sureScreen">
              确认筛选
            </a-button>
          </div>
        </div>
      </div>
    </a-dropdown>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  ControlTypeEnum,
  CONDITIONARR,
  formateType,
} from '@/views/iot-platform/device-manage/running-status/formTypeEnum';
import { uuid } from '@triascloud/utils';

@Component({
  methods: {
    formateType,
  },
})
export default class ScreenList extends Vue {
  @Prop({ type: Array, default: () => [] }) dataList;
  @Prop({ type: String, default: '' }) fIdentifier;
  @Prop({ type: Boolean, default: false }) rightScreenFlag;

  @Watch('dataList', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal.length > 0) {
      this.dataList = newVal;
      this.initList();
    } else {
      this.ruleList = [];
      this.ruleSelectList = [];
    }
  }
  @Watch('fIdentifier', { immediate: true })
  changeItem(newVal, oldVal) {
    if (newVal !== oldVal) {
      this.ruleList = [];
    }
  }
  @Watch('rightScreenFlag', { immediate: true })
  changeFlag() {
    this.ruleList = [];
  }
  initList() {
    const arr1 = this.defaultRuleList;
    let arr2 = [];
    if (this.dataList.length > 1) {
      arr2 = this.dataList.map(v => {
        return {
          ...v,
          fi: this.fIdentifier,
          si: v.identifier,
        };
      });
    } else {
      arr2 = this.dataList.map(v => {
        return {
          ...v,
          fi: this.fIdentifier,
          si: '',
        };
      });
    }
    this.ruleSelectList = arr1.concat(arr2);
  }

  dropDownVisible = false;
  get getLength() {
    const len = this.ruleList.length;
    return `（${len}）`;
  }

  handleMenuClick(e) {
    e.preventDefault();
  }

  cleanScreen() {
    this.$emit('clearScreen');
    this.ruleList = [];
  }

  form = {
    logic: 'AND',
  };
  logicData = [
    {
      value: 'AND',
      text: '且',
    },
    {
      value: 'OR',
      text: '或',
    },
  ];
  handleLogicChange(val) {
    this.form.logic = val;
  }
  ruleList = [];
  ruleSelectList = [];
  addRule() {
    if (this.ruleList.length > 0) {
      const row = this.ruleList.at(-1);
      if (!row.typeId) {
        this.$message.warning('请完善添加的筛选规则');
        return;
      }
    }
    this.ruleList.push({
      id: uuid(),
      typeId: undefined,
      type: '',
      rule: undefined,
      ruleSelectList: [],
      selectList: [],
      value: '',
      other: '',
      minValue: undefined,
      maxValue: undefined,
    });
  }
  changeCondition(valId, index, flag = 'add') {
    const idx = this.ruleSelectList.findIndex(v => v.id === valId);
    const row = this.ruleSelectList[idx];
    const type = row.type;
    const arr = CONDITIONARR;
    const a1 = arr.slice(0, arr.length - 2);
    const o = {
      int: a1,
      int32: a1,
      float: a1,
      date: a1,
      double: a1,
      text: [arr[0], arr[1], arr[6], arr[7], arr[8], arr[9]],
      bool: [arr[0], arr[1]],
      enum: [arr[0], arr[1], arr[6], arr[7], arr[8], arr[9]],
      file: [arr[6], arr[7]],
      image: [arr[6], arr[7]],
    };
    this.ruleList[index].ruleSelectList = o[type];
    this.$nextTick(() => {
      const obj = this.ruleList[index];
      obj.typeId = row.id;
      obj.other = row.other;
      obj.type = type;
      obj.path = row.si ? `${row.fi}.${row.si}` : row.fi;
      if (flag === 'add') {
        obj.rule = obj.ruleSelectList[0].value;
        obj.value = undefined;
      }
      if (type === ControlTypeEnum.Enum) {
        if (row.other?.length) {
          const other = JSON.parse(row.other);
          obj.selectList = other;
          obj.value = other[0].value;
        }
      }
    });
  }
  changeRule(val, row) {
    row.value = undefined;
    row.rule = val;
  }
  handleTimeChange(v, row, flag) {
    if (flag === 'ok') {
      row.value = v;
    }
  }
  queryCondition = [];
  sureScreen() {
    if (this.ruleList.length === 0) {
      this.$message.warning('请添加筛选条件');
      return;
    }
    this.queryCondition = this.ruleList.map(v => {
      const o = {
        operator: v.rule,
        parameterPath: v.path,
        type: v.type.toUpperCase(),
        value: '',
      };
      if (this.getIntType(v)) {
        if (v.rule === 'RANGE') {
          o['minValue'] = v.minValue;
          o['maxValue'] = v.maxValue;
        } else {
          o.value = Number(v.value);
        }
      } else if (v.type === ControlTypeEnum.Date) {
        if (v.rule === 'RANGE') {
          if (v.value) {
            o['minValue'] = this.$moment(v.value[0]).valueOf();
            o['maxValue'] = this.$moment(v.value[1]).valueOf();
          } else {
            o['minValue'] = undefined;
            o['maxValue'] = undefined;
          }
        } else {
          o.value = this.$moment(v.value).valueOf();
        }
      } else {
        o.value = v.value;
      }
      return o;
    });
    this.dropDownVisible = false;
    this.$emit('screenData', {
      data: this.queryCondition,
      logic: this.form.logic,
    });
  }
  getIntType(item) {
    return (
      item.type === ControlTypeEnum.Int ||
      item.type === 'int32' ||
      item.type === ControlTypeEnum.Float ||
      item.type === ControlTypeEnum.Double
    );
  }
  showInput(item) {
    const a = ['EMPTY', 'NOT_EMPTY'].indexOf(item.rule) === -1;
    const b = this.getIntType(item);
    return a && !b;
  }
  validateMin(item) {
    let value = Number(item.value);
    if (item.type === ControlTypeEnum.Int || item.type === 'int32') {
      const v = value.toString().split('.').length > 1;
      if (v) {
        item.value = +value.toString().split('.')[0];
      }
      if (value > 2147483648) {
        item.value = 2147483648;
      } else if (value < -2147483648) {
        item.value = -2147483648;
      }
    } else if (item.type === ControlTypeEnum.Float) {
      if (value > 3.4e38) {
        item.value = 3.4e38;
      } else if (value < -3.4e38) {
        item.value = -3.4e38;
      }
    } else {
      if (value > 1.79e308) {
        item.value = 1.79e308;
      } else if (value < -1.79e308) {
        item.value = -1.79e308;
      }
    }
  }

  validateMin1(item) {
    let min = Number(item.minValue);
    const max = Number(item.maxValue);
    if (max !== null && min > max) {
      min = max;
    }
    if (item.type === ControlTypeEnum.Int || item.type === 'int32') {
      if (min < -2147483648) {
        min = -2147483648;
      }
    } else if (item.type === ControlTypeEnum.Float) {
      if (min < Number(-3.4e38)) {
        min = Number(-3.4e38);
      }
    } else {
      if (min < Number(-1.79e308)) {
        min = Number(-1.79e308);
      }
    }
    item.minValue = min;
  }

  validateMax(item) {
    let min = Number(item.minValue);
    let max = Number(item.maxValue);
    if (min !== null && min > max) {
      max = min;
    }
    if (item.type === ControlTypeEnum.Int || item.type === 'int32') {
      if (max > 2147483648) {
        max = 2147483648;
      }
    } else if (item.type === ControlTypeEnum.Float) {
      if (max > Number(3.4e38)) {
        max = Number(3.4e38);
      }
    } else {
      if (max > Number(1.79e308)) {
        max = Number(1.79e308);
      }
    }
    item.maxValue = max;
  }

  deleteRule(idx) {
    this.ruleList.splice(idx, 1);
  }
  textDate() {
    const arr = CONDITIONARR;
    const a1 = arr.slice(0, arr.length - 2);
    return {
      text: [arr[0], arr[1], arr[6], arr[7], arr[8], arr[9]],
      date: a1,
    };
  }
  get defaultRuleList() {
    return [
      {
        id: uuid(),
        typeId: 'createTime',
        type: 'date',
        name: '创建时间',
        rule: 'EQUAL',
        fi: 'triascloud_create_time',
        si: undefined,
        path: 'triascloud_create_time',
        ruleSelectList: this.textDate().date,
        selectList: [],
        value: '',
        other: '',
      },
    ];
  }
}
</script>

<style lang="less" module>
.screenList {
  .rangeValue {
    display: flex;
    align-items: center;
  }
  .dropdown {
    background: var(--dropdown-bg);
    width: 900px;
    border-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    box-shadow: 0 0 14px 0 var(--dropdown-shadow);

    .content {
      padding: 15px;
      max-height: 350px;
      overflow-y: auto;
      position: relative;

      .scroll {
        display: flex;

        .logic {
          width: 36px;
          position: relative;
          display: flex;
          align-items: center;
          margin-right: 10px;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 12px;
            border: 1px solid var(--btn-border);
            border-right: none;
            pointer-events: none;
          }

          :global {
            .ant-select-sm .ant-select-arrow {
              right: 2px;
            }

            .ant-select-selection--single .ant-select-selection__rendered {
              margin: 0 4px;
            }

            .ant-select-selection {
              background-color: var(--block-bg);
            }
            .ant-select {
              width: 35px;
            }
          }
        }

        .select {
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &:last-child {
            margin-bottom: 0;
          }

          .deleteIcon {
            font-size: 20px;
            margin-left: 10px;
            cursor: pointer;
            color: var(--delete);
          }
        }
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 5px 5px 10px;
      border-bottom: 1px solid var(--form-border);

      .action {
        font-size: 14px;
        cursor: pointer;

        &:hover {
          color: var(--primary);
        }
      }
    }

    :global {
      .ant-input-affix-wrapper {
        &:last-child {
          margin-right: 0;
        }

        .ant-input {
          padding-right: 11px;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }

        .anticon-close-circle {
          display: none;
        }

        &:hover .ant-input {
          padding-right: 30px;
        }

        &:hover .anticon-close-circle {
          display: inline-block;
        }
      }

      .ant-select-selection--multiple .ant-select-selection__choice.zoom-leave {
        display: none;
      }
    }
  }
  .dropdownLogic {
    :global {
      .ant-select-dropdown-menu-item {
        padding: 4px 8px;
        line-height: 18px;
      }
    }
  }
}
</style>
