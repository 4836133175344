var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.running},[_c('div',{class:_vm.$style.left},[_c('div',{class:_vm.$style.search},[_c('a-input',{staticStyle:{"width":"100%"},attrs:{"placeholder":"数据点名称筛选","allowClear":""},on:{"pressEnter":_vm.getList,"change":function () { return _vm.changeValue(); }},model:{value:(_vm.form.topSearch),callback:function ($$v) {_vm.$set(_vm.form, "topSearch", $$v)},expression:"form.topSearch"}},[_c('span',{attrs:{"slot":"prefix"},slot:"prefix"},[_c('a-icon',{attrs:{"type":"search"}})],1)])],1),_c('a-spin',{attrs:{"spinning":_vm.loading}},[(_vm.leftData.length)?_c('div',{class:_vm.$style.list},_vm._l((_vm.leftData),function(item,idx){return _c('div',{key:idx,class:[
            _vm.$style.listItem,
            _vm.activeIndex === idx ? _vm.$style.actived : '' ],on:{"click":function($event){return _vm.clickItem(idx)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_c('div',{class:_vm.$style.list},[_c('EmptyContent')],1)])],1),_c('div',{class:_vm.$style.right},[_c('div',{class:_vm.$style.time},[_c('div',[(!_vm.isDetail)?_c('ScreenList',{attrs:{"dataList":_vm.rightData,"fIdentifier":_vm.fIdentifier,"rightScreenFlag":_vm.rightScreenFlag},on:{"screenData":_vm.screenData,"clearScreen":_vm.clearScreen}}):_vm._e()],1),(_vm.showTime)?_c('span',[_vm._v("设备最近更新时间："+_vm._s(_vm.updateTime))]):_vm._e()]),_c('a-spin',{attrs:{"spinning":_vm.loading}},[(_vm.rightData.length)?_c('div',{class:_vm.$style.box},_vm._l((_vm.rightData),function(item,idx){return _c('div',{key:idx,class:_vm.$style.rightCard},[_c('div',{class:_vm.$style.text},[_c('span',{class:_vm.$style.label},[_vm._v(" "+_vm._s(_vm.isDetail ? _vm.itemName : item.name)+" ")]),_c('span',[_c('a-icon',{class:_vm.$style.primaryIcon,attrs:{"type":"unordered-list"},on:{"click":function($event){return _vm.openDetail(item)}}})],1)]),_c('div',{class:[
              _vm.$style.mid,
              item.type === 'image' || item.type === 'file' ? _vm.$style.h80 : '' ]},[(_vm.isDetail)?_c('span',{class:_vm.$style.primarySpan,on:{"click":function($event){return _vm.toModel(item, _vm.itemName)}}},[_vm._v(" 详情 ")]):(!_vm.isValue(item.value))?_c('div',{class:_vm.$style.empty},[_c('a-empty',{attrs:{"image":_vm.simpleImage}})],1):(item.type === 'image')?_c('span',[_c('x-oss-image',{staticClass:"x-file-icon",staticStyle:{"margin-top":"-15px"},attrs:{"size":"32","oss-path":item.value},on:{"click":function () {
                    _vm.$preview(item.value, 'image');
                  }}})],1):(item.type === 'file')?_c('span',{class:_vm.$style.fileWrap},[_c('x-icon',{class:_vm.$style.icon,attrs:{"type":"tc-icon-file-unknown"},on:{"click":function () {
                    _vm.download(item.value, _vm.itemName);
                  }}})],1):(_vm.isValue(item.value))?_c('div',{class:_vm.$style.value},[_c('a-popover',{key:item.id,attrs:{"placement":"top","getPopupContainer":function (t) { return t.parentNode; },"overlayClassName":_vm.$style.tipsPop}},[_c('template',{slot:"content"},[_c('span',[_vm._v(_vm._s(item.value))]),_c('span',{staticStyle:{"cursor":"pointer","color":"var(--primary)"},on:{"click":function($event){return _vm.copy(item.value)}}},[_vm._v(" 复制 ")])]),(('' + item.value).length > 30)?_c('div',{class:_vm.$style.label},[_c('span',[_vm._v(" "+_vm._s(item.value)+" ")])]):_vm._e()],2),(('' + item.value).length <= 30)?_c('div',{class:_vm.$style.label},[_c('span',[_vm._v(" "+_vm._s(item.value)+" ")])]):_vm._e(),(!_vm.isDetail)?_c('div',{class:_vm.$style.unit},[_vm._v(" "+_vm._s(item.unit)+" ")]):_vm._e()],1):_vm._e()])])}),0):_c('div',{class:_vm.$style.emptyImage},[_c('EmptyContent')],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }