import request from '../request';

// * /iot-platform/tenant
const serviceName = '/iot-platform/tenant';

// * 获取设备列表
export function getPlatformList(data) {
  return request(`${serviceName}/device/page`, {
    method: 'POST',
    body: data,
  });
}
// * 获取产品分类列表
export function gatCategoryList(data) {
  return request('/iot-platform/category/list', {
    method: 'GET',
    body: data,
  });
}
// * 添加日志
export function addLog(data) {
  return request(`${serviceName}/device/apiLog/add`, {
    method: 'POST',
    body: data,
  });
}
// * 添加设备
export function addPlatform(data) {
  return request(`${serviceName}/device/bind`, {
    method: 'POST',
    body: data,
  });
}
// * 获取设备信息
export function getDeviceInfo(devicePkId) {
  return request(`${serviceName}/device/info/${devicePkId}`, {
    method: 'GET',
  });
}
// * 编辑设备信息
export function editDeviceInfo(data) {
  return request(`${serviceName}/device`, {
    method: 'PUT',
    body: data,
  });
}
// * 编辑设备产品列表
export function getDeviceProductList() {
  return request(`${serviceName}/device/product`, {
    method: 'GET',
  });
}
// * 品类获取产品列表
export function getProductListById(categoryId) {
  return request(`${serviceName}/device/product/category/${categoryId}`, {
    method: 'GET',
  });
}
// * 获取扩展包列表
export function getExpendList(data) {
  return request(`${serviceName}/device/expandList`, {
    method: 'GET',
    body: data,
  });
}
// * 创建扩展包订单
export function buyExpendList(data) {
  return request(`${serviceName}/device/order`, {
    method: 'POST',
    body: data,
  });
}
// * 获取设备基础信息（根据设备面板进行返回）
export function getDeviceDetail(devicePkId) {
  return request(`${serviceName}/device/baseInfo/${devicePkId}`, {
    method: 'GET',
  });
}

// * 设备运行状态
export function getDeviceRunning(data) {
  return request(`${serviceName}/device/deviceRunningStatus`, {
    method: 'GET',
    body: data,
  });
}

// * 设备运行状态筛选
export function getDeviceRunningScreen(data) {
  return request(`${serviceName}/device/deviceRunningStatus/byParent`, {
    method: 'POST',
    body: data,
  });
}
// * 设备上报事件
export function getDeviceEvent(data) {
  return request(`${serviceName}/device/reportedEvents`, {
    method: 'POST',
    body: data,
  });
}
// * 设备字段展示与否
export function getDeviceFileds(devicePkId) {
  return request(`${serviceName}/device/panelFieldStatus/${devicePkId}`, {
    method: 'GET',
  });
}
// * 删除设备
export function deleteDevice(data) {
  return request(`${serviceName}/device`, {
    method: 'DELETE',
    body: data,
  });
}

/** @description 设备地图轨迹 */
export function previewPosition(data) {
  return request(`${serviceName}/device/previewPosition`, {
    method: 'POST',
    body: data,
  });
}

// * 属性字段指标详情
export function deviceFiledsDetail(data) {
  return request(`${serviceName}/device/fieldIndicatorsDetail`, {
    method: 'POST',
    body: data,
  });
}

// * 属性字段指标详情 数组结构体
export function deviceFiledsDetailAs(data) {
  return request(`${serviceName}/device/fieldIndicatorsDetail/arrayStructure`, {
    method: 'POST',
    body: data,
  });
}
// * 属性字段指标详情图表
export function deviceFiledsDetailChart(data) {
  return request(`${serviceName}/device/fieldIndicatorsDetail/list`, {
    method: 'POST',
    body: data,
  });
}
// * 属性字段指标详情图表 bool enum
export function deviceFiledsDetailChartBE(data) {
  return request(`${serviceName}/device/fieldIndicatorsDetail/list/group`, {
    method: 'POST',
    body: data,
  });
}
// * 属性字段指标详情 导出
export function deviceFiledsDetailExport(data) {
  return request(`${serviceName}/device/export/indicatorsDetail`, {
    method: 'POST',
    body: data,
  });
}
// * 事件 导出
export function deviceEventExport(data) {
  return request(`${serviceName}/device/export/reportedEvents`, {
    method: 'POST',
    body: data,
  });
}
