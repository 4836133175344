<template>
  <div :class="$style.cardView">
    <a-spin :spinning="loading">
      <div v-if="listData.length" :class="$style.box">
        <div
          v-for="item in listData"
          :key="item.pkId"
          :class="[
            $style.card,
            item.deviceStatus === 'EXPIRED' ? $style.cardDisabled : '',
          ]"
          @click="viewDevice(item)"
        >
          <div :class="$style.info">
            <div :class="$style.header">
              <x-oss-image
                basePath="/oss/oss"
                :ossPath="item.headFigure"
                :class="$style.img"
              />
              <div
                :class="[$style.title, $style.width225]"
                :title="item.deviceCode"
              >
                {{ item.deviceCode }}
              </div>
              <!-- <div :class="$style.tag" v-if="item.deviceStatus === 'EXPIRED'">
                已过期
              </div> -->
            </div>
            <div style="margin-bottom: 5px" :class="$style.infoLabel">
              <span :class="$style.label">产品名称：</span>
              <span>{{ item.productName }}</span>
            </div>
            <div :class="$style.infoLabel">
              <span :class="$style.label">设备类型：</span>
              <span>{{ DEVICE_TYPE[item.deviceType].text }}</span>
            </div>
            <a-divider />
            <div :class="$style.btn">
              <div>
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>编辑</span>
                  </template>
                  <a-icon
                    v-if="$p.action('UPDATE')"
                    @click.stop="editDevice(item)"
                    :class="[$style.primaryIcon, $style.btnIcon]"
                    type="edit"
                  />
                </a-tooltip>
                <!--                <a-tooltip placement="top">-->
                <!--                  <template slot="title">-->
                <!--                    <span>查看</span>-->
                <!--                  </template>-->
                <!--                  <a-icon-->
                <!--                    -->
                <!--                    @click="viewDevice(item.devicePkId)"-->
                <!--                    :class="$style.primaryIcon"-->
                <!--                    type="eye"-->
                <!--                  />-->
                <!--                </a-tooltip>-->
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>移除</span>
                  </template>
                  <x-icon
                    v-if="$p.action('DELETE')"
                    @click.stop="deleteDevice(item)"
                    type="tc-icon-delete"
                    :class="[$style.deleteIcon, $style.btnIcon]"
                  />
                </a-tooltip>
              </div>
              <span :style="{ color: getTextColor(item) }">
                {{ changeStatusText(item) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-else :class="$style.empty">
        <EmptyContent />
      </div>
      <div style="display: flex;justify-content: flex-end;margin-bottom: -10px">
        <a-pagination
          show-quick-jumper
          v-model="pagination.current"
          :total="pagination.total"
          :page-size="pagination.pageSize"
          :showSizeChanger="true"
          :pageSizeOptions="pagination.pageSizeOptions"
          :showTotal="total => `总数${total}条`"
          @change="changePagination"
          @showSizeChange="changePagination"
        />
      </div>
    </a-spin>
  </div>
</template>

<script>
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EmptyContent from '@/components/empty-content';
import { DEVICE_TYPE } from './platform-enum';

@Component({
  components: {
    EmptyContent,
  },
})
export default class DeviceCard extends Vue {
  @Prop({ type: Array, default: () => [] }) listData;
  @Prop({ type: Boolean, default: false }) loading;
  @Prop({ type: Object, default: () => {} }) pagination;

  get getTotal() {
    return this.listData.length;
  }

  @Watch('listData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal) {
      this.listData = newVal;
    }
  }
  @Watch('pagination', { immediate: true, deep: true })
  changePagenation(newVal) {
    if (newVal) {
      this.pagination = newVal;
    }
  }
  DEVICE_TYPE = DEVICE_TYPE;
  changePagination(current, pageSize) {
    this.$emit('changePage', {
      current,
      pageSize,
      pageSizeOptions: ['20', '30', '40'],
    });
  }
  deleteDevice(record) {
    this.$emit('deleteDevice', record);
  }
  editDevice(record) {
    this.$emit('editDevice', record.devicePkId);
  }
  viewDevice(record) {
    if (this.$p.action('READ') && record.deviceStatus !== 'EXPIRED') {
      this.$emit('viewDevice', record.devicePkId);
    } else {
      this.$message.error('设备已过期');
    }
  }
  changeStatusText(item) {
    if (item.deviceStatus === 'EXPIRED') {
      return '已过期';
    } else {
      return item.onLine ? '在线' : '离线';
    }
  }
  getTextColor(item) {
    if (item.deviceStatus === 'EXPIRED') {
      return 'var(--delete)';
    } else {
      return item.onLine ? 'var(--success)' : 'var(--font-info)';
    }
  }
}
</script>

<style lang="less" module>
.cardView {
  .box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 10px; /* 可选项：设置单元格之间的间隔 */
    margin-bottom: 10px;
    cursor: pointer;
    .card {
      width: 315px;
      height: 165px;
      background-color: var(--nav-bg);
      border: 1px solid var(--border);
      border-radius: 5px;
      padding: 10px;
      display: flex;
      &:hover {
        border-color: var(--primary-70);
      }
      .img {
        height: 50px;
        width: 50px;
        border-radius: 5px;
      }
      .info {
        margin-left: 5px;
        flex: 1;
        .header {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          justify-content: space-between;
          .title {
            font-size: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 10px;
          }
          .tag {
            height: 20px;
            width: 50px;
            background-color: rgba(255, 181, 181, 0.3);
            text-align: center;
            border-radius: 5px;
            color: var(--delete);
          }
        }
        .infoLabel {
          font-size: 16px;
        }
        .label {
          color: var(--font-info);
        }
        .btn {
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .deleteIcon {
            color: var(--delete);
            cursor: pointer;
          }
          .primaryIcon {
            color: var(--primary);
            margin-right: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .cardDisabled {
    cursor: not-allowed;
    pointer-events: none;
    filter: grayscale(100%);
    background-color: #f9f9f9 !important;
    .btnIcon {
      pointer-events: none;
    }
  }
  .empty {
    width: 100%;
    height: 670px;
    display: flex;
    :global {
      .x-empty-content--image {
        height: 270px;
      }
    }
  }
  .width165 {
    width: 165px;
  }
  .width225 {
    width: 225px;
  }
  :global {
    .ant-divider-horizontal {
      margin: 10px 0 8px;
    }
  }
}
</style>
