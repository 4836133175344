<script>
import moment from 'moment';
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { DateDefault, PARAM_STATUS, PARAM_TYPE } from './utils';
const utcTime = (now = new Date()) => {
  const year = now.getUTCFullYear();
  const month = now.getUTCMonth() + 1;
  const day = now.getUTCDate();
  const hours = now.getUTCHours();
  const minutes = now.getUTCMinutes();
  const se = now.getUTCSeconds();
  const ms = now.getUTCMilliseconds();

  return `${year}-${month}-${day} ${hours}:${minutes}:${se}:${ms}`;
};
@Component()
export default class RenderDate extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() checkDefaultValue;
  @Prop() schemeByType;
  @Prop({ type: String, default: 'id' }) useKey;

  render() {
    const item = this.syncItem;
    this.checkDefaultValue(item);

    let ComponentPicker;
    if (item.defaultType && item.defaultType === DateDefault.CURRENT) {
      let value = this.syncStore.get(item[this.useKey]);
      let date;
      if (item.format === 'x' || item.format === 'X') {
        date = Number(moment(Date.now()).format(item.format));
      } else if (
        item.format === 'YYYY-MM-DDTHH:mm:ss.SSS[Z]' ||
        item.format === 'YYYY-Wo'
      ) {
        const now = new Date();
        value = utcTime(now);
        date = now.toISOString();
      } else if (item.format === 'HH:mm') {
        const now = moment(Date.now());
        value = `${now.year()}-${now.month()}-${now.day()} ${value}`;
        date = moment(Date.now()).format(item.format);
      } else {
        date = moment(Date.now()).format(item.format);
      }
      this.syncStore.set(item[this.useKey], date);

      const props = {
        format: item.format === 'X' ? 'x' : item.format,
        value: value,
        onChange: (_, d) => {
          this.syncStore.set(item[this.useKey], d);
        },
      };
      ComponentPicker = <x-date-picker {...{ props }} />;
    } else {
      ComponentPicker = (
        <x-date-picker
          format={item.format === 'X' ? 'x' : item.format}
          v-model={this.syncStore.data[item[this.useKey]]}
        />
      );
    }

    return (
      <a-form-model-item
        class={this.$style.formItem}
        rules={[
          { required: item.required, message: '不能为空', trigger: 'change' },
        ]}
        prop={item[this.useKey]}
      >
        {item[PARAM_TYPE] === PARAM_STATUS.IN ? (
          <a-row class={this.$style.labelHeader}>
            <a-col span={6}>
              <label class={item.required ? 'ant-form-item-required' : ''}>
                {item.name}
              </label>
            </a-col>
            <a-col span={4}>{item.type}</a-col>
            <a-col span={14}>{ComponentPicker}</a-col>
          </a-row>
        ) : (
          <div>
            <label
              slot="label"
              class={[
                this.$style.label,
                item.required ? 'ant-form-item-required' : '',
              ]}
            >
              <span>{item.name}</span>
              {this.tooltip(item)}
              {this.param(item)}
            </label>
            {ComponentPicker}
          </div>
        )}
      </a-form-model-item>
    );
  }
}
</script>
<style lang="less" module>
.formItem {
  :global {
    .ant-form-item-required {
      display: flex;
      align-items: center;
    }
  }
}
.label {
  display: flex;
  align-items: center;
}
.labelHeader {
  height: 50px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border);
}
</style>
