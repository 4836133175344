<script>
import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import { PARAM_STATUS, PARAM_TYPE } from './utils';
@Component()
export default class RenderBoolean extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() checkDefaultValue;
  @Prop() schemeByType;
  @Prop({ type: String, default: 'id' }) useKey;

  render() {
    const item = this.syncItem;
    this.checkDefaultValue(item);
    return (
      <a-form-model-item
        class={this.$style.formItem}
        rules={[
          { required: item.required, message: '不能为空', trigger: 'blur' },
        ]}
        prop={item[this.useKey]}
      >
        {item[PARAM_TYPE] === PARAM_STATUS.IN ? (
          <a-row class={this.$style.labelHeader}>
            <a-col span={6}>
              <label class={item.required ? 'ant-form-item-required' : ''}>
                {item.name}
              </label>
            </a-col>
            <a-col span={4}>{item.type}</a-col>
            <a-col span={14}>
              <a-switch
                checked={this.syncStore.get(item[this.useKey]) ? true : false}
                onChange={target => {
                  this.syncStore.set(item[this.useKey], target ? 1 : 0);
                }}
                checked-children={item.is}
                un-checked-children={item.not}
              />
            </a-col>
          </a-row>
        ) : (
          <div>
            <label
              slot="label"
              class={[
                this.$style.label,
                item.required ? 'ant-form-item-required' : '',
              ]}
            >
              <span>{item.name}</span>
              {this.tooltip(item)}
              {this.param(item)}
            </label>
            <a-switch
              checked={this.syncStore.get(item[this.useKey]) ? true : false}
              onChange={target => {
                this.syncStore.set(item[this.useKey], target ? 1 : 0);
              }}
              checked-children={item.is}
              un-checked-children={item.not}
            />
          </div>
        )}
      </a-form-model-item>
    );
  }
}
</script>
<style lang="less" module>
.formItem {
  :global {
    .ant-form-item-required {
      display: flex;
      align-items: center;
    }
  }
}
.label {
  display: flex;
  align-items: center;
}
.labelHeader {
  height: 50px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border);
}
</style>
