<script>
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { UploadService } from '@triascloud/services';
import { crossStorageModule } from '@/enum/store';
import { PARAM_STATUS, PARAM_TYPE } from './utils';

const customService = new UploadService('/oss/oss');

@Component()
export default class RenderFile extends Vue {
  @PropSync('item') syncItem;
  @PropSync('store') syncStore;
  @Prop() tooltip;
  @Prop() param;
  @Prop() schemeByType;
  @Prop() checkDefaultValue;
  @Prop({ type: String, default: 'id' }) useKey;
  @crossStorageModule.State('tenant') tenant;

  handleRemove(file) {
    const index = this.fileList.indexOf(file);
    const newFileList = this.fileList.slice();
    newFileList.splice(index, 1);
    this.fileList = newFileList;
    this.syncStore.set(this.syncItem[this.useKey], this.fileList);
  }
  async customUpload(options, item) {
    const isFileDisabled = count => {
      return this.fileList.length >= count;
    };
    if (isFileDisabled(item.maxCount)) {
      this.$message.warn(`最多上传${item.maxCount}个文件！`);
      return;
    }
    if (options.file.size > item.maxSize * 1024 * 1024) {
      this.$message.warn(`文件${options.file.name}大小超过${item.maxSize}M！`);
      return;
    }
    this.uploadOptions = options;
    let ossPath = await this.uploadFile(options);
    const abPath = await customService.getAuth(ossPath);
    this.fileList.push({
      uid: options.file.uid,
      name: options.file.name,
      status: 'done',
      url: abPath,
      ossPath,
      file: options.file,
    });
    this.syncStore.set(this.syncItem[this.useKey], this.fileList);
  }
  fileList = [];
  isMultiple(item) {
    return item.maxCount > 1;
  }
  async uploadFile(file) {
    const uploadOptions = this.uploadOptions;
    const files = file.file;
    const pkId = this.tenant.pkId;
    const uploadPath = `${pkId}/connector/dp-manage/file`;
    const res = await customService.upload(
      files,
      uploadPath,
      e => {
        e.addEventListener('responseprogress', p => {
          uploadOptions.onProgress(
            { percent: +Math.round((p.loaded / p.total) * 100).toFixed(2) },
            files,
          );
        });
      },
      false,
    );
    uploadOptions.onSuccess(res, files);
    return res;
  }
  render() {
    const item = this.syncItem;
    if (Array.isArray(item.val) && item.val.length) {
      this.fileList = item.val;
    }
    return (
      <a-form-model-item
        class={this.$style.formItem}
        rules={[
          { required: item.required, message: '不能为空', trigger: 'blur' },
        ]}
        prop={item[this.useKey]}
      >
        {item[PARAM_TYPE] === PARAM_STATUS.IN ? (
          <a-row class={this.$style.labelHeader}>
            <a-col span={6}>
              <label class={item.required ? 'ant-form-item-required' : ''}>
                {item.name}
              </label>
            </a-col>
            <a-col span={4}>{item.type}</a-col>
            <a-col span={14}>
              <a-upload
                remove={this.handleRemove}
                customRequest={options => this.customUpload(options, item)}
                file-list={this.fileList}
                multiple={item.maxCount > 1}
              >
                {this.fileList.length < 1 ? (
                  <div>
                    <a-button>
                      <a-icon type="upload" /> 选择文件
                    </a-button>
                  </div>
                ) : (
                  ''
                )}
              </a-upload>
            </a-col>
          </a-row>
        ) : (
          <div>
            <label
              slot="label"
              class={[
                this.$style.label,
                item.required ? 'ant-form-item-required' : '',
              ]}
            >
              <span>{item.name}</span>
              {this.tooltip(item)}
              {this.param(item)}
            </label>
            <a-upload
              remove={this.handleRemove}
              customRequest={options => this.customUpload(options, item)}
              file-list={this.fileList}
              multiple={item.maxCount > 1}
            >
              {this.fileList.length < 1 ? (
                <div>
                  <a-button>
                    <a-icon type="upload" /> 选择文件
                  </a-button>
                </div>
              ) : (
                ''
              )}
            </a-upload>
          </div>
        )}
      </a-form-model-item>
    );
  }
}
</script>
<style lang="less" module>
.formItem {
  :global {
    .ant-form-item-required {
      display: flex;
      align-items: center;
    }
  }
}
.label {
  display: flex;
  align-items: center;
}
.labelHeader {
  height: 50px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--border);
}
</style>
