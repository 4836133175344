<template>
  <div :class="$style.deviceBox">
    <layout-content>
      <template v-slot:header-left>
        <span :class="$style.title">
          设备管理
        </span>
      </template>
      <div v-if="!viewShowFlag" :class="$style.deviceContent" slot="content">
        <div
          style="display: flex; align-items: center;justify-content: space-between"
        >
          <div style="display: flex;align-items: center">
            <div :class="$style.footer">
              <div :class="$style.iconList">
                <div
                  v-for="(item, idx) in viewSelectList"
                  :key="item.id"
                  :class="[$style.tabItem]"
                  @click="changeView(item, idx)"
                >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>{{ item.text }}</span>
                    </template>
                    <span
                      :class="[
                        $style.icon,
                        item.actived ? $style.activeView : '',
                      ]"
                    >
                      <x-icon :type="item.icon" />
                    </span>
                  </a-tooltip>
                </div>
              </div>
            </div>
            <a-select
              style="width: 210px"
              v-model="form.product"
              placeholder="全部设备"
              allowClear
              :getPopupContainer="t => t.parentNode"
              @change="changeProduct"
            >
              <a-select-option
                v-for="item in productList"
                :value="item.pkId"
                :key="item.pkId"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-select
              style="width: 210px"
              v-model="form.deviceType"
              placeholder="设备类型"
              allowClear
              :getPopupContainer="t => t.parentNode"
              @change="changeDeviceType"
            >
              <a-select-option value="DIRECT_CONNECTION">
                直连设备
              </a-select-option>
              <a-select-option value="GATEWAY">
                网关设备
              </a-select-option>
              <a-select-option value="SUB_GATEWAY">
                网关子设备
              </a-select-option>
            </a-select>
            <a-select
              style="width: 210px"
              v-model="form.online"
              placeholder="设备状态"
              allowClear
              :getPopupContainer="t => t.parentNode"
              @change="changeOnline"
            >
              <a-select-option value="online">
                在线
              </a-select-option>
              <a-select-option value="offline">
                离线
              </a-select-option>
            </a-select>
            <a-input
              v-model="form.deviceCode"
              style="width: 210px;margin-left: 10px;"
              placeholder="设备码/备注"
              allowClear
              @change="val => changeInput(val.target.value)"
            />
          </div>
          <div :class="$style.btnGroup">
            <a-button
              v-if="$p.action('CREATE')"
              type="primary"
              icon="plus"
              style="margin-right: 10px"
              @click="addOrEditForm({})"
            >
              添加设备
            </a-button>
            <a-button
              v-if="$p.action('BUY_SERVER')"
              :class="$style.defaultBtn"
              @click="serviceList"
            >
              增值服务
            </a-button>
            <!--            <a-button :class="$style.defaultBtn">IOT市场</a-button>-->
          </div>
        </div>
        <div :class="$style.cardDiv">
          <DeviceCard
            v-if="viewflag === VIEWSELECT.CARD"
            :listData="tableData"
            :loading="tableLoading"
            @editDevice="editDevice"
            @viewDevice="viewDevice"
            @deleteDevice="deleteDevice"
            :pagination="pagination"
            @changePage="handlePagination"
          />
          <a-table
            v-else
            :columns="columns"
            :dataSource="tableData"
            :pagination="pagination"
            :loading="tableLoading"
            :scroll="{ x: 1800 }"
            :row-key="record => record.deviceId"
            @change="handlePagination"
          >
            <template slot="deviceCode" slot-scope="text, record">
              <span>{{ text }}</span>
              <x-icon
                :class="$style.iconQrcode"
                @click.stop="handleQrcode(record)"
                type="tc-icon-qrcode"
              ></x-icon>
            </template>
            <template slot="verifyCode" slot-scope="text, record">
              <div :class="$style.code">
                <div style="margin-right: 10px;width: 100px">
                  {{ record.showCode ? text : '**********' }}
                </div>
                <a-icon
                  v-if="!record.showCode"
                  :class="$style.primaryIcon"
                  type="eye"
                  @click="record.showCode = !record.showCode"
                />
                <a-icon
                  v-else
                  :class="$style.primaryIcon"
                  type="eye-invisible"
                  @click="record.showCode = !record.showCode"
                />
              </div>
            </template>
            <template slot="action" slot-scope="text, record">
              <span :class="$style.buttonGroup" @click.stop>
                <a
                  v-if="$p.action('UPDATE')"
                  @click.stop="editDevice(record.devicePkId)"
                >
                  编辑
                </a>
                <a
                  v-if="$p.action('READ') && record.deviceStatus !== 'EXPIRED'"
                  :class="$style.button"
                  @click.stop="viewDevice(record.devicePkId)"
                >
                  查看
                </a>
                <a
                  v-if="$p.action('DELETE')"
                  :class="[$style.button, $style.red]"
                  @click.stop="deleteDevice(record)"
                >
                  移除
                </a>
              </span>
            </template>
          </a-table>
        </div>
      </div>
      <div v-if="viewShowFlag" slot="content">
        <ViewPage @cancel="viewShowFlag = false" :devicePkId="viewDevicePkId" />
      </div>
    </layout-content>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { LayoutContent } from '@triascloud/x-blocks';
import DeviceCard from '@/views/iot-platform/device-manage/device-card.vue';
import ViewPage from '@/views/iot-platform/device-manage/view-page.vue';
import {
  deleteDevice,
  getDeviceInfo,
  getDeviceProductList,
  getPlatformList,
} from '@/services/iot-platform';
import { createFormModal, createModal } from '@triascloud/x-components';
import PlatformForm from '@/views/iot-platform/device-manage/platform-form.vue';
import { debounce } from 'lodash-decorators';
import ServiceList from '@/views/iot-platform/device-manage/service-list.vue';
import { DEVICE_TYPE } from '@/views/iot-platform/device-manage/platform-enum';
import DeleteTips from '@/views/iot-platform/device-manage/delete-tips.vue';
import ShowQrcode from '@/views/iot-platform/device-manage/show-qrcode.vue';

// * 切换视图
const VIEWSELECT = {
  // * 列表
  LIST: 'list',
  // * 卡片
  CARD: 'card',
};

@Component({
  components: {
    LayoutContent,
    DeviceCard,
    ViewPage,
    ShowQrcode,
  },
})
export default class DeviceManage extends Vue {
  form = {
    product: undefined,
    deviceCode: '',
    deviceType: undefined,
    online: undefined,
  };
  viewflag = VIEWSELECT.CARD;
  VIEWSELECT = VIEWSELECT;
  changeProduct(val) {
    this.form.product = val;
    this.pagination.current = 1;
    this.getList();
  }
  changeDeviceType(val) {
    this.form.deviceType = val;
    this.pagination.current = 1;
    this.getList();
  }
  changeOnline(val) {
    this.form.online = val;
    this.pagination.current = 1;
    this.getList();
  }
  @debounce(300)
  changeInput(val) {
    this.form.deviceCode = val;
    this.pagination.current = 1;
    this.getList();
  }
  viewSelectList = [
    {
      id: 1,
      text: '卡片',
      icon: 'tc-icon-postcode-filled',
      type: VIEWSELECT.CARD,
      actived: true,
    },
    {
      id: 2,
      text: '列表',
      icon: 'tc-icon-table-filled',
      type: VIEWSELECT.LIST,
      actived: false,
    },
  ];
  changeView(row, idx) {
    this.viewflag = row.type;
    this.viewSelectList.forEach(v => (v.actived = false));
    this.viewSelectList[idx].actived = true;
    if (row.type === VIEWSELECT.LIST) {
      this.pagination = {
        ...this.pagination,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40'],
      };
    } else {
      this.pagination = {
        ...this.pagination,
        pageSize: 20,
        pageSizeOptions: ['20', '30', '40'],
      };
    }
    this.pagination.current = 1;
    this.getList();
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => <span>{index + 1}</span>,
        title: '序号',
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '设备码',
        dataIndex: 'deviceCode',
        ellipsis: true,
        scopedSlots: { customRender: 'deviceCode' },
      },
      {
        align: 'left',
        title: '验证码',
        dataIndex: 'verifyCode',
        scopedSlots: { customRender: 'verifyCode' },
      },
      {
        align: 'left',
        title: '在线状态',
        dataIndex: 'onLine',
        customRender: text => (
          <a-tag
            style={{ color: text ? 'var(--success)' : 'var(--font-info)' }}
          >
            {text ? '在线' : '离线'}
          </a-tag>
        ),
      },
      {
        align: 'left',
        title: '产品名称',
        dataIndex: 'productName',
        ellipsis: true,
      },
      {
        align: 'left',
        title: '设备类型',
        dataIndex: 'deviceType',
        ellipsis: true,
        customRender: text => (
          <a-tag style={{ color: DEVICE_TYPE[text].color }}>
            {DEVICE_TYPE[text].text}
          </a-tag>
        ),
      },
      {
        align: 'left',
        title: '最近添加时间',
        dataIndex: 'bindTime',
        customRender: text => this.formatTime(text),
      },
      {
        align: 'left',
        title: '设备有效期',
        dataIndex: 'expireTime',
        customRender: (text, row) => this.expireTime(text, row),
      },
      {
        align: 'left',
        title: '备注',
        dataIndex: 'remark',
        width: 250,
        ellipsis: true,
      },
      {
        width: 140,
        title: '操作',
        fixed: 'right',
        key: 'operation',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }

  get defaultPagenation() {
    return {
      current: 1,
      total: 0,
      showSizeChanger: true,
      showTotal: total => this.$t('todo.flow.total', { total }),
    };
  }
  pagination = {
    current: 1,
    pageSize: 20,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['20', '30', '40'],
    showTotal: total => this.$t('todo.flow.total', { total }),
  };

  mounted() {
    this.getList();
    this.getProductList();
  }

  handleQrcode(row) {
    const deviceItem = {
      idxProductId: row.idxProductId,
      deviceCode: row.deviceCode,
    };
    createModal(() => <ShowQrcode deviceItem={deviceItem} />, {
      width: 300,
      title: '二维码',
    });
  }

  expireTime(text, row) {
    if (row.deviceStatus === 'EXPIRED') {
      return '已过期';
    } else {
      if (!text) {
        return '长期有效';
      } else {
        return this.formatTime(text);
      }
    }
  }
  formatTime(val) {
    return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  productList = [];
  async getProductList() {
    try {
      this.productList = await getDeviceProductList();
    } catch {
      return false;
    }
  }
  tableData = [];
  tableLoading = false;
  async getList() {
    const params = {
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.form.product) {
      params['productId'] = this.form.product;
    }
    if (this.form.deviceType) {
      params['deviceType'] = this.form.deviceType;
    }
    if (this.form.deviceCode) {
      params['input'] = this.form.deviceCode;
    }
    if (this.form.online) {
      params['isOnline'] = this.form.online === 'online' ? true : false;
    }
    this.tableLoading = true;
    try {
      this.tableData = [];
      const { records, total, current, size } = await getPlatformList(params);
      this.tableLoading = false;
      this.tableData = records.map(v => {
        return {
          ...v,
          showCode: false,
        };
      });
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
    } catch {
      this.tableLoading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = {
      ...this.defaultPagenation,
      ...data,
    };
    this.getList();
  }

  viewShowFlag = false;
  async editDevice(pkId) {
    try {
      const res = await getDeviceInfo(pkId);
      await this.addOrEditForm({
        pkId: res.devicePkId,
        mainSort: res.categoryTree.pkId,
        subSort: res.categoryTree.childrenList,
        deviceCode: res.deviceCode,
        checkCode: res.verifyCode,
        remark: res.remark,
        productName: res.productName,
      });
    } catch {
      return false;
    }
  }
  viewDevicePkId = '';
  viewDevice(val) {
    this.viewShowFlag = true;
    this.viewDevicePkId = val;
  }
  async deleteDevice(row) {
    await createFormModal(() => <DeleteTips />, {
      width: 450,
      title: '操作确认',
      onOk: () => {
        this.deleteDevices(row.devicePkId);
      },
    });
  }
  async deleteDevices(id) {
    try {
      const formData = new FormData();
      formData.append('devicePkId', id);
      await deleteDevice(formData);
      this.pagination.current = 1;
      await this.getList();
      this.$message.success('移除设备成功');
    } catch {
      return false;
    }
  }
  async addOrEditForm(obj = {}) {
    const disabledFlag = Object.keys(obj).length !== 0;
    try {
      const result = await createFormModal(
        () => <PlatformForm disabledFlag={disabledFlag} editData={obj} />,
        {
          width: 470,
          title: disabledFlag ? '添加设备' : '编辑设备',
        },
      );
      if (result) {
        this.pagination.current = 1;
        this.form.deviceCode = '';
        this.form.product = undefined;
        await this.getList();
      }
    } catch {
      return false;
    }
  }
  async serviceList() {
    try {
      const result = await createModal(
        () => <ServiceList onClose={() => result.handleClose()} />,
        {
          width: 1100,
          title: '增值服务',
        },
      );
      if (result) {
        this.pagination.current = 1;
        this.form.deviceCode = '';
        this.form.product = undefined;
        await this.getList();
      }
    } catch {
      return false;
    }
  }
}
</script>

<style lang="less" module>
.deviceBox {
  flex: 1;
  display: flex;
  overflow: hidden;
  .title {
    font-size: 16px;
  }
  .deviceContent {
    padding: 10px 28px;
    min-width: 850px;
    .viewSelect {
      display: inline-flex;
      width: 32px;
      height: 32px;
      background: var(--modal-header-bg);
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      color: var(--font-sub);
      cursor: pointer;
      .viewType {
        &:hover {
          color: var(--primary);
        }
      }
    }
    .iconQrcode {
      margin-left: 10px;
      cursor: pointer;
      color: var(--primary);
    }
    .btnGroup {
      .defaultBtn {
        &:hover {
          color: var(--primary);
          background-color: var(--nav-bg);
          border-color: var(--primary);
        }
      }
    }
    .cardDiv {
      margin-top: 10px;
      .buttonGroup {
        display: flex;
        justify-content: flex-start;
        .red {
          color: #f72f2f;
        }
        .button {
          margin-left: 10px;
        }
      }
    }
    .footer {
      .iconList {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .tabItem {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          position: relative;
          .icon {
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: var(--modal-header-bg);
            border-radius: 5px;
            font-size: 16px;
            color: var(--font);
            &:hover {
              background: var(--primary);
              color: #fff;
            }
          }
          .activeView {
            background: var(--primary);
            color: #fff;
          }
          .text {
            font-size: 16px;
            color: var(--font);
            margin-top: 8px;
          }
          &:not(:first-child) {
            margin: 0 10px;
          }
        }
      }
    }
  }
  .primaryIcon {
    cursor: pointer;
    color: var(--primary);
  }
  .code {
    display: flex;
    align-items: center;
  }
  :global {
    .ant-table-wrapper .ant-table-pagination {
      display: flex;
      justify-content: flex-end;
      text-align: left;
    }
  }
}
</style>
