<template>
  <div :class="$style.chart" ref="line">
    <div
      v-if="isNoData"
      style="display: flex; justify-content: center; height: 100%"
    >
      <EmptyContent />
    </div>
    <div v-else ref="lineChart" style="width: 100%; height: 500px"></div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import dayjs from 'dayjs';
import EmptyContent from '@/components/empty-content/empty-content.vue';
import { nowSize } from '@/utils/common';
import theme from '@/utils/theme/theme';
import { crossStorageModule } from '@/enum/store';

@Component({
  components: {
    EmptyContent,
  },
})
export default class LineChart extends Vue {
  @Prop({ type: Array, default: () => [] }) chartData;
  @Prop({ type: Array, default: () => [] }) unit;
  @Prop({ type: Boolean, default: true }) isSmooth;
  @Prop({ type: String, default: 'circle' }) symbol;
  @Prop({ type: String, default: 'day' }) xInterValType;
  @Prop({ type: String, default: '16%' }) chartBottom;
  @Prop({ type: String, default: '16%' }) chartRight;
  @Prop({ type: String, default: 'MIN' }) ruleType;
  /**
   * @Description 是否展示lagend
   */
  @Prop({ type: Boolean, default: false }) showLegend;
  @Prop({
    type: Array,
    default: () => [
      '#4FA2FF',
      '#00C56E',
      '#FFCC37',
      '#EF6666',
      '#0065C4',
      '#07B9B9',
      '#3BA272',
      '#EA7CCC',
      '#91CC75',
      '#FAC858',
    ],
  })
  colorArray;

  @crossStorageModule.State('skin') skin;
  @Watch('skin')
  changeSkin() {
    this.initChart();
  }

  @Watch('chartData', { immediate: true, deep: true })
  changeData(newVal) {
    if (newVal && newVal[0]?.data.length) {
      this.isNoData = false;
      this.$nextTick(() => {
        this.initChart();
      });
    } else {
      if (this.myChart) {
        this.myChart.dispose();
        this.myChart = null;
      }
      this.isNoData = true;
    }
  }

  @Watch('ruleType', { immediate: true })
  changeType(newVal) {
    this.ruleType = newVal;
  }

  mounted() {
    this.initChart();
  }
  get dataZoom() {
    const themeType = theme[this.skin.theme];
    return [
      {
        type: 'slider',
        show: true,
        start: 0,
        end: 100,
        xAxisIndex: [0],
        borderColor: themeType.primary30,
        fillerColor: themeType.primary30,
        handleStyle: {
          color: themeType.primary,
        },
        moveHandleStyle: {
          color: themeType.primary30,
        },
        dataBackground: {
          lineStyle: {
            color: themeType.primary80,
          },
          areaStyle: {
            color: themeType.primary30,
          },
        },
        brushStyle: {
          color: themeType.primary30,
        },
        emphasis: {
          moveHandleStyle: {
            color: themeType.primary80,
          },
        },
      },
      {
        type: 'inside',
      },
    ];
  }
  isNoData = true;
  myChart = null;
  initChart() {
    if (!this.myChart) {
      const el = this.$refs.lineChart;
      if (!el) return;
      this.myChart = echarts.init(el);
    }
    const zoom = this.dataZoom;
    const length = this.chartData[0]?.data.length;
    if (length === 0) return;
    if (length > 40) {
      zoom[0].show = true;
      const num = Math.round((40 / length) * 10000) / 100.0;
      zoom[0].end = Math.floor(num);
    } else {
      zoom[0].show = false;
      zoom[0].start = 0;
    }
    const dayType = {
      minute: 'YYYY-MM-DD HH:mm',
      hour: 'YYYY-MM-DD HH:mm',
      day: 'YYYY-MM-DD',
    };
    const s1 = dayType[this.xInterValType];
    const xData = this.chartData[0].time.map(v => {
      return dayjs(v).format(s1);
    });
    const themeMode = theme[this.skin.mode];
    const themeType = theme[this.skin.theme];
    const options = {
      color: themeType.primary,
      tooltip: {
        trigger: 'axis',
        formatter: params => this.toolTip(params),
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: themeMode.mainBg,
        textStyle: {
          color: themeMode.font,
          fontSize: nowSize(14),
        },
        extraCssText: 'border-radius:5px;border-color: var(--primary-fade-80)',
        padding: [nowSize(10)],
        // position: (pos, params, el, elRect, size) => {
        //   const obj = { top: 10 };
        //   obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
        //   return obj;
        // },
      },
      grid: {
        left: '10%',
        right: this.chartRight,
        bottom: this.chartBottom,
        top: '5%',
        containLabel: true,
      },
      dataZoom: zoom,
      xAxis: {
        type: 'category',
        data: xData,
        boundaryGap: false,
        axisLabel: {
          color: themeMode.font,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: themeMode.font,
        },
        minInterval: 1,
        splitLine: {
          lineStyle: {
            color: themeMode.border,
          },
        },
      },
      series: [],
    };
    // 可以在这里根据 showLegend 和 legendData 配置 series
    this.chartData.forEach(v => {
      const o = {
        name: v.name,
        smooth: this.isSmooth, // 平滑
        symbol: this.symbol, // 不展示圆点
        type: 'line',
        sampling: 'lttb',
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: themeType.primary,
            },
            {
              offset: 1,
              color: themeType.primary30,
            },
          ]),
        },
        data: v.data,
      };
      options.series.push(o);
    });
    if (this.showLegend) {
      const legendData = this.chartData.map(v => v.name);
      options['legend'] = {
        type: 'scroll',
        icon: 'roundRect',
        bottom: 50,
        data: legendData,
        textStyle: {
          color: themeMode.font,
        },
        pageTextStyle: {
          color: themeMode.font,
        },
      };
    }
    this.myChart.setOption(options, true);
  }
  toolTip(params) {
    let result = `<div class="event-chart-box"><div>`;
    params.forEach((item, idx) => {
      let unit;
      let name;
      if (this.ruleType !== 'COUNT') {
        unit = this.unit;
        name = item.seriesName;
      } else {
        name = '数据数量';
        unit = '条';
      }
      const time = item.axisValue;
      const str1 = `<div>
									${time}
								</div>`;
      const str2 = `
          			<div class="warn-label">
            			<span class="color-label" style="background-color: ${item.color}"></span>
									<span>
										${name}：${params[idx].value}${unit}
									</span>
          			</div>
        			`;
      if (idx === 0) {
        result += str1 + str2;
      } else {
        result += str2;
      }
    });
    return result + '</div></div>';
  }
}
</script>

<style lang="less" module>
.chart {
  height: calc(100% - 80px);
  :global {
    .event-chart-box {
      .color-label {
        display: inline-block;
        margin-right: 6px;
        border-radius: 10px;
        width: 10px;
        height: 10px;
      }
    }
  }
}
</style>
