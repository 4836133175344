<template>
  <div :class="$style.running">
    <div :class="$style.left">
      <div :class="$style.search">
        <a-input
          v-model="form.topSearch"
          placeholder="数据点名称筛选"
          style="width: 100%"
          allowClear
          @pressEnter="getList"
          @change="() => changeValue()"
        >
          <span slot="prefix">
            <a-icon type="search" />
          </span>
        </a-input>
      </div>
      <a-spin :spinning="loading">
        <div v-if="leftData.length" :class="$style.list">
          <div
            v-for="(item, idx) in leftData"
            :key="idx"
            :class="[
              $style.listItem,
              activeIndex === idx ? $style.actived : '',
            ]"
            @click="clickItem(idx)"
          >
            {{ item.name }}
          </div>
        </div>
        <div v-else :class="$style.list">
          <EmptyContent />
        </div>
      </a-spin>
    </div>
    <div :class="$style.right">
      <div :class="$style.time">
        <div>
          <ScreenList
            v-if="!isDetail"
            :dataList="rightData"
            :fIdentifier="fIdentifier"
            :rightScreenFlag="rightScreenFlag"
            @screenData="screenData"
            @clearScreen="clearScreen"
          />
        </div>
        <span v-if="showTime">设备最近更新时间：{{ updateTime }}</span>
      </div>
      <a-spin :spinning="loading">
        <div v-if="rightData.length" :class="$style.box">
          <div
            v-for="(item, idx) in rightData"
            :key="idx"
            :class="$style.rightCard"
          >
            <div :class="$style.text">
              <span :class="$style.label">
                {{ isDetail ? itemName : item.name }}
              </span>
              <span>
                <a-icon
                  :class="$style.primaryIcon"
                  type="unordered-list"
                  @click="openDetail(item)"
                />
              </span>
            </div>
            <div
              :class="[
                $style.mid,
                item.type === 'image' || item.type === 'file' ? $style.h80 : '',
              ]"
            >
              <span
                v-if="isDetail"
                :class="$style.primarySpan"
                @click="toModel(item, itemName)"
              >
                详情
              </span>
              <div v-else-if="!isValue(item.value)" :class="$style.empty">
                <a-empty :image="simpleImage" />
              </div>
              <span v-else-if="item.type === 'image'">
                <x-oss-image
                  style="margin-top: -15px"
                  class="x-file-icon"
                  size="32"
                  :oss-path="item.value"
                  @click="
                    () => {
                      $preview(item.value, 'image');
                    }
                  "
                />
              </span>
              <span v-else-if="item.type === 'file'" :class="$style.fileWrap">
                <x-icon
                  :class="$style.icon"
                  @click="
                    () => {
                      download(item.value, itemName);
                    }
                  "
                  type="tc-icon-file-unknown"
                />
              </span>
              <div v-else-if="isValue(item.value)" :class="$style.value">
                <a-popover
                  placement="top"
                  :getPopupContainer="t => t.parentNode"
                  :overlayClassName="$style.tipsPop"
                  :key="item.id"
                >
                  <template slot="content">
                    <span>{{ item.value }}</span>
                    <span
                      style="cursor: pointer;color: var(--primary)"
                      @click="copy(item.value)"
                    >
                      复制
                    </span>
                  </template>
                  <div
                    v-if="('' + item.value).length > 30"
                    :class="$style.label"
                  >
                    <span>
                      {{ item.value }}
                    </span>
                  </div>
                </a-popover>
                <div
                  v-if="('' + item.value).length <= 30"
                  :class="$style.label"
                >
                  <span>
                    {{ item.value }}
                  </span>
                </div>
                <div v-if="!isDetail" :class="$style.unit">
                  {{ item.unit }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else :class="$style.emptyImage">
          <EmptyContent />
        </div>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { createModal } from '@triascloud/x-components';
import CardDetail from '@/views/iot-platform/device-manage/running-status/card-detail.vue';
import {
  getDeviceRunning,
  getDeviceRunningScreen,
} from '@/services/iot-platform';
import { Empty } from 'ant-design-vue';
import {
  changeBoolValue,
  changeData,
  changeEnumValue,
} from '@/views/iot-platform/device-manage/platform-enum';
import InnerModel from '@/views/iot-platform/device-manage/inner-model.vue';
import EmptyContent from '@/components/empty-content';
import { debounce } from 'lodash-decorators';
import { clipboardWrite, deepClone, download, uuid } from '@triascloud/utils';
import ScreenList from '@/views/iot-platform/device-manage/running-status/screen-list.vue';

@Component({
  methods: { download },
  components: {
    InnerModel,
    EmptyContent,
    ScreenList,
  },
})
export default class RunningStatus extends Vue {
  @Prop({ type: String, default: '' }) devicePkId;
  @Prop({ type: Boolean, default: false }) showTime;
  @Prop({ type: String, default: '' }) refreshFlag;

  form = {
    topSearch: '',
  };

  @Watch('refreshFlag')
  changeRefreshFlag(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.activeIndex = 0;
      this.getList();
    }
  }
  activeIndex = 0;
  simpleImage;
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  }
  mounted() {
    this.getList();
  }
  get rightData() {
    return this.leftData[this.activeIndex]?.children[0]?.data || [];
  }
  get isDetail() {
    return this.leftData[this.activeIndex]?.children[0]?.isDetail;
  }
  get itemName() {
    return this.leftData[this.activeIndex]?.children[0]?.name;
  }
  get updateTime() {
    return this.leftData[this.activeIndex]?.lastUpdateTime;
  }

  get themeId() {
    return this.leftData[this.activeIndex]?.themeId;
  }

  get fIdentifier() {
    return this.leftData[this.activeIndex]?.identifier;
  }
  isValue(val) {
    return val !== null && val !== undefined && val !== '';
  }

  rightScreenFlag = false;
  @debounce(300)
  changeValue() {
    this.activeIndex = 0;
    this.rightScreenFlag = true;
    this.getList();
  }
  leftData = [
    {
      children: [],
    },
  ];
  loading = false;
  async getList() {
    try {
      const params = {
        devicePkId: this.devicePkId,
      };
      if (this.form.topSearch) {
        params['keyword'] = this.form.topSearch;
      }
      this.loading = true;
      const res = await getDeviceRunning(params);
      this.leftData = this.changeResult(res);
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  changeResult(records) {
    const arr = [];
    records.forEach(v => {
      const o = this.changePropertyData(v, 'f');
      if (v.data.dataType === 'ARRAY' && v.data.childDataType === 'STRUCT') {
        o.children = [
          {
            isDetail: true,
            name: v.data.name,
            data: [v.sonData],
          },
        ];
      } else {
        const arr1 = [];
        v.sonData.forEach(s => {
          const o1 = this.changePropertyData(s, 'c');
          arr1.push(o1);
        });
        o.children = [
          {
            isDetail: false,
            name: v.data.name,
            data: arr1,
          },
        ];
      }
      arr.push(o);
    });
    return arr;
  }

  beforeScreenData = [];
  async screenData(val) {
    const params = {
      devicePkId: this.devicePkId,
      themeId: this.themeId,
      queryCondition: val.data,
      symbol: val.logic,
    };
    this.loading = true;
    try {
      const res = await getDeviceRunningScreen(params);
      const arr = this.changeResult(res);
      const idx = arr.findIndex(v => v.identifier === this.fIdentifier);
      this.leftData[this.activeIndex].children[0].data =
        arr[idx].children[0].data;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }

  clearScreen() {
    this.leftData[this.activeIndex].children[0].data = this.beforeScreenData;
  }
  formatTime(val) {
    return val ? this.$moment(val).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  async copy(v) {
    await clipboardWrite(v);
    this.$message.success('复制成功');
  }
  changePropertyData(row, flag) {
    const data = row.data;
    const o = {
      id: uuid(),
      identifier: data.identifier,
      name: data.name,
      themeId: row.themeId,
      other: data.other,
      type: data.dataType.toLowerCase(),
      children: [],
    };
    if (flag === 'f') {
      o['lastUpdateTime'] = this.formatTime(row.lastUpdateTime);
      return o;
    } else {
      if (o.type === 'enum') {
        o['value'] = changeEnumValue(data.value, data.other);
      } else if (o.type === 'date') {
        o['value'] = this.formatTime(data.value);
      } else if (o.type === 'bool') {
        o['value'] = changeBoolValue(data.value, data.other);
      } else if (o.type === 'array') {
        o['value'] = data.value ? JSON.stringify(data.value) : '-';
      } else {
        o['value'] = data.value;
      }
      o['unit'] = data.unit;
      return o;
    }
  }
  clickItem(idx) {
    this.activeIndex = idx;
    this.beforeScreenData = deepClone(this.rightData);
  }
  toModel(key, name) {
    const arr = changeData(key);
    const modal = createModal(
      () => <InnerModel modelData={arr} onClose={() => modal.handleClose()} />,
      {
        width: '1000px',
        title: name,
      },
    );
  }
  openDetail(row) {
    const obj = {
      devicePkId: this.devicePkId,
      identifier: '',
      themeId: this.themeId,
      other: '',
      name: '',
      unit: '',
      type: '',
    };
    if (Array.isArray(row)) {
      obj.name = this.itemName;
      obj.type = 'AS';
      obj.identifier = this.fIdentifier;
    } else {
      obj.name = row.name;
      obj.unit = row.unit;
      obj.type = row.type;
      obj.other = row.other;
      if (row.identifier !== this.fIdentifier) {
        obj.identifier = `${this.fIdentifier}.${row.identifier}`;
      } else {
        obj.identifier = this.fIdentifier;
      }
    }
    createModal(() => <CardDetail paramsData={obj} />, {
      title: obj.name + '详情',
      width: 800,
    });
  }
}
</script>

<style lang="less" module>
.running {
  display: flex;
  height: calc(100vh - 329px);
  .left {
    border-right: 1px solid var(--border);
    width: 300px;
    .search {
      padding: 20px;
    }
    .list {
      overflow: hidden auto;
      height: calc(100vh - 400px);
      .listItem {
        display: flex;
        align-items: center;
        height: 50px;
        position: relative;
        padding: 0 16px;
        cursor: pointer;
        &:hover {
          color: var(--font-active);
          background-color: var(--inline-menu-active);
        }
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          border-right: 4px solid var(--primary);
          transform: scaleY(0.0001);
          opacity: 0;
          transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
            opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
          content: '';
        }
      }
      .actived {
        color: var(--font-active);
        background-color: var(--inline-menu-active);
        &::after {
          transform: scaleY(1);
          opacity: 1;
          transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
            opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }
    }
  }
  .right {
    flex: 1;
    .time {
      color: var(--font-info);
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      padding-left: 10px;
    }
    .box {
      overflow: hidden auto;
      height: calc(100vh - 374px);
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(5, 1fr);
      gap: 10px; /* 可选项：设置单元格之间的间隔 */
      margin-bottom: 10px;
      padding: 0 10px 0 10px;
      .rightCard {
        //width: 250px;
        height: 120px;
        background-color: var(--nav-bg);
        border: 1px solid var(--border);
        border-radius: 5px;
        padding: 10px;
        &:hover {
          border-color: var(--primary-70);
        }
        .text {
          display: flex;
          justify-content: space-between;
          .label {
            color: var(--font-info);
          }
          .primaryIcon {
            color: var(--primary);
            cursor: pointer;
            font-size: 16px;
          }
        }
        .h80 {
          height: 80px;
        }
        .mid {
          font-size: 16px;
          margin-top: 20px;
          .value {
            display: flex;
            .label {
              max-width: 230px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .unit {
              font-size: 16px;
              margin-left: 5px;
            }
          }
        }
        .empty {
          width: 100%;
          height: 80px;
          display: flex;
          margin-top: -15px;
        }
        .cardTime {
          color: var(--font-info);
          margin-top: 10px;
        }
      }
    }
    .emptyImage {
      height: calc(100vh - 320px);
    }
  }
  :global {
    .ant-empty-normal {
      margin: 10px 0;
    }
    .x-file-icon {
      width: 200px;
      height: 75px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .primarySpan {
    color: var(--primary);
    cursor: pointer;
  }
  .fileWrap {
    display: flex;
    .icon {
      font-size: 32px;
    }
  }
}
.tipsPop {
  :global {
    .ant-popover-content {
      width: 300px;
      line-break: anywhere;
      max-height: 250px;
      overflow: hidden auto;
    }
  }
}
</style>
