<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import { generateMap, clipboardWrite, delay } from '@triascloud/utils';
import { cloneDeep, isObject } from 'lodash';
import dayjs from 'dayjs';
import { globalSocket } from '@triascloud/message-hub';
import {
  checkArrayType,
  ControlTypeEnum,
  PARAM_TYPE,
  PARAM_STATUS,
  checkDefaultValue,
  flatFields,
  ReactiveStore,
  STATUS,
  formatBody,
  parseUrlQuery,
  headerOptions,
  fetchBody,
  prefixAPiFormat,
  parseHeaderVariable,
  parseUrlPath,
  promptValidate,
  eachResList,
} from './utils';
import RenderImage from './render-image.vue';
import RenderNumber from './render-number.vue';
import RenderFile from './render-file.vue';
import RenderDataPoint from './render-datapoint.vue';
import RenderArray from './render-array.vue';
import RenderBoolean from './render-boolean.vue';
import RenderEnum from './render-enum.vue';
import RenderDate from './render-date.vue';
import RenderStruct from './render-struct.vue';
import {
  getBasicSettings,
  getApiSettings,
  getTriggerSettings,
  getEquipmentControl,
  debug,
  preInterface,
  prefixApi,
} from '@/services/iot-platform/tenant-device';
import { addLog } from '@/services/iot-platform';
import { IotSocket } from '@/enum/socket';
import EmptyContent from '@/components/empty-content';

const MODE = {
  /** @name 参数模式 */
  PARAM: 'PARAM',
  /** @name 日志模式 */
  LOG: 'LOG',
};

@Component({
  components: { EmptyContent },
})
export default class DeviceControl extends Vue {
  @Prop({ type: String }) devicePkId;
  mounted() {
    this.init();
  }

  beforeDestroy() {
    this.closeSocket();
  }

  get actionTxt() {
    if (this.isAMQP) {
      if (this.SocketEmited) {
        return '停止';
      } else {
        return '执行';
      }
    } else {
      return '执行';
    }
  }

  /** @name socket是否发送了 */
  SocketEmited = false;
  SocketDisconnect = true;
  async handleWebSocket() {
    if (!this.SocketEmited) {
      this.SocketEmited = true;
      let body = formatBody({
        list: this.filterListCopy,
        store: this.inStore,
        key: 'id',
      });
      body = Object.assign(body, {
        apiId: this.apiId,
      });
      globalSocket.send(IotSocket.AmqpDebug, body);
      this.SocketDisconnect = false;
      this.initSocket();
    } else {
      this.closeSocket();
    }
  }
  closeSocket() {
    this.destorySocket();
    if (this.isAMQP) {
      this.SocketEmited = false;
      this.SocketDisconnect = true;
      globalSocket.send(IotSocket.AmqpDisconnect, {
        apiId: this.apiId,
      });
    }
  }
  handleAmqpDebug({ data }) {
    this.resResult = JSON.parse(data);
    /** @name 限制只展示100条 */
    if (this.jsonArray.length > 100) {
      this.jsonArray.pop();
    }
    this.jsonArray.unshift({
      time: Date.now(),
      format: 'YYYY/MM/DD HH:mm:ss.SSS',
      result: isObject(data) ? JSON.stringify(data) : data,
    });
  }
  socketError(error) {
    this.$message.error(error.data);
  }
  initSocket() {
    if (this.isAMQP) {
      globalSocket.on(IotSocket.AmqpEvent, this.handleAmqpDebug);
      globalSocket.on(IotSocket.AmqpEventError, this.socketError);
    }
  }
  destorySocket() {
    if (this.isAMQP) {
      globalSocket.off(IotSocket.AmqpEvent, this.handleAmqpDebug);
      globalSocket.off(IotSocket.AmqpEventError, this.socketError);
    }
  }

  hasReady = false;
  detail = {};
  apiId = '';
  apiList = [];
  apiHasMore = false;
  async init() {
    const res = await getEquipmentControl(this.devicePkId);
    if (res) {
      this.apiList = res;
    }
    this.apiHasMore = true;
  }

  isAMQP = false;
  trigger = {};
  apiSetting = {};
  async deviceClick(apiId) {
    this.hasReady = false;
    this.resMode = MODE.PARAM;
    this.jsonArray = [];
    this.resResult = {};
    this.apiId = apiId;
    const [basic, apiSettings, trigger] = await Promise.allSettled([
      getBasicSettings(this.devicePkId, apiId),
      getApiSettings(this.devicePkId, apiId),
      getTriggerSettings(this.devicePkId, apiId),
    ]);

    if (basic.value.subscriptionType === 'AMQP') {
      this.isAMQP = true;
    } else {
      this.isAMQP = false;
    }

    this.trigger = trigger.value;
    this.apiSetting = apiSettings.value;

    let method = basic.value.requestMethod;
    this.detail = {
      ...basic.value,
      requestHeaderParam: basic.value.requestHeaderParam
        ? parseHeaderVariable(basic.value.requestHeaderParam, [])
        : [],
      requestUrlParam: basic.value.requestUrlParam
        ? checkArrayType(
            JSON.parse(basic.value.requestUrlParam),
            method === 'POST' ? 'query' : '',
            () => {
              this.hasReady = true;
            },
          )
        : [],
      requestBodyParam: basic.value.requestBodyParam
        ? checkArrayType(
            JSON.parse(basic.value.requestBodyParam).data,
            method === 'POST' ? 'body' : '',
            () => {
              this.hasReady = true;
            },
          )
        : [],
      returnParam: basic.value.returnParam
        ? checkArrayType(JSON.parse(basic.value.returnParam), '', () => {
            this.hasReady = true;
          })
        : [],
      /** @name post方法参数的提交方式 */
      formatType: basic.value.requestBodyParam
        ? JSON.parse(basic.value.requestBodyParam).postFormat
        : '',
    };

    this.initInStore();
    this.initInFilterStore();
  }

  get requestURL() {
    let url = this.detail.requestUrl;
    if (!this.hasReady) return url;
    const method = this.detail.requestMethod;
    const options = {
      list: this.filterListCopy,
      store: this.inStore,
      key: 'id',
    };
    if (method === 'POST') {
      options.postType = 'query';
    }
    const body = formatBody(options);
    url = parseUrlPath(url, body);
    url = parseUrlQuery(url, method, body);
    return url;
  }

  inStore = undefined;
  hasRender = false;
  initInStore() {
    let data = generateMap(flatFields(this.filterListCopy), 'id', item => {
      checkDefaultValue(item);
      return item.val;
    });
    this.inStore = new ReactiveStore(data);
    this.hasRender = true;
  }
  inFilterStore = undefined;
  /** @description 订阅方的入参列表  */
  initInFilterStore() {
    let data = generateMap(flatFields(this.filterList), 'id', item => {
      checkDefaultValue(item);
      return item.val;
    });
    this.inFilterStore = new ReactiveStore(data);
  }

  status = STATUS.SUBSCRIBER;
  /** @name 请求参数列表 */
  get filterList() {
    return this.inList(true);
  }
  /** @name 请求参数列表 */
  get filterListCopy() {
    return this.inList(false);
  }
  /**
   * @description 入参列表
   * @param { Boolean } filterStatus 是否筛选【入参对象】属性【STATUS】
   */
  inList(filterStatus = false) {
    let array = [];
    let list = [];
    if (this.detail.requestUrlParam) {
      list = list.concat(this.detail.requestUrlParam);
    }
    if (this.detail.requestBodyParam) {
      list = list.concat(this.detail.requestBodyParam);
    }
    array = list.filter(v => v.pkId === v.id);
    if (filterStatus && this.status !== '') {
      array = array.filter(v => v.inParams && v.inParams.includes(this.status));
    }
    return array;
  }

  schemeByType(v, store) {
    const mapComponent = {
      [ControlTypeEnum.Input]: RenderNumber,
      [ControlTypeEnum.Double]: RenderNumber,
      [ControlTypeEnum.Float]: RenderNumber,
      [ControlTypeEnum.Int]: RenderNumber,
      [ControlTypeEnum.Switch]: RenderBoolean,
      [ControlTypeEnum.Enum]: RenderEnum,
      [ControlTypeEnum.Date]: RenderDate,
      [ControlTypeEnum.File]: RenderFile,
      [ControlTypeEnum.Image]: RenderImage,
      [ControlTypeEnum.Array]: RenderArray,
      [ControlTypeEnum.Struct]: RenderStruct,
      [ControlTypeEnum.DataPoint]: RenderDataPoint,
    };
    const DefaultComponent = mapComponent[v.type];
    return (
      <DefaultComponent
        item={v}
        store={store}
        tooltip={this.renderTooltip}
        param={() => {}}
        checkDefaultValue={checkDefaultValue}
        schemeByType={this.schemeByType}
      />
    );
  }
  /** @name FormItem名称提示语 */
  renderTooltip(item) {
    if (item.description) {
      return (
        <a-tooltip
          overlayClassName={
            item.description.length > 100 ? this.$style.tooltip : ''
          }
          arrowPointAtCenter={true}
        >
          <div
            {...{
              domProps: {
                innerHTML: item.description,
              },
            }}
            class={this.$style.boxHtml}
            slot={'title'}
          ></div>
          <a-icon type={'info-circle'} class={this.$style.tooltipIcon} />
        </a-tooltip>
      );
    } else {
      return '';
    }
  }
  renderScheme(list = [], type, store) {
    return list.map((v, idx) => {
      Vue.set(v, PARAM_TYPE, type);
      return <div key={'scheme' + idx}>{this.schemeByType(v, store)}</div>;
    });
  }

  filterResList = [];
  /** @name 解析 */
  async handleParse(result) {
    try {
      result = isObject(result) ? result : JSON.parse(result);
      this.filterResList = cloneDeep(this.detail.returnParam);
      await eachResList(this.filterResList, -1, '', result);
      let data = generateMap(flatFields(this.filterResList), 'id', item => {
        checkDefaultValue(item);
        return item.val;
      });
      this.outStore = new ReactiveStore(data);
      this.handChangeResMode();
      await this.promptMessage();
    } catch (error) {
      this.$message.error('解析失败！');
    }
  }
  /** @name 出参规则提示语 */
  async promptMessage() {
    try {
      const msgList = promptValidate(
        this.apiSetting.outgoingRules,
        this.outStore,
        this.filterResList,
      );
      for (let msg of msgList) {
        this.$message.warn({
          content: msg,
          duration: 1.5,
        });
        await delay(1500);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  jsonArray = [];
  async handleCopy(item) {
    try {
      await clipboardWrite(item);
      this.$message.success('复制成功');
    } catch (error) {
      this.$message.success('复制失败');
    }
  }
  renderLogMode() {
    return this.jsonArray.map((v, i) => {
      return (
        <section class={this.$style.jsonBox} key={'jsonLog' + i}>
          <div>
            <span>{dayjs(v.time).format(v.format)}</span>
          </div>
          <div class={this.$style.jsonString}>{v.result}</div>
          <div class={this.$style.jsonBorder}></div>
          <div class={this.$style.copyBox}>
            <span
              class={this.$style.copy}
              onClick={() => this.handleCopy(v.result)}
            >
              复制
            </span>
            <span
              class={this.$style.copy}
              onClick={() => this.handleParse(v.result)}
            >
              解析
            </span>
          </div>
        </section>
      );
    });
  }
  resMode = MODE.PARAM;
  handChangeResMode() {
    if (this.resMode === MODE.PARAM) {
      this.resMode = MODE.LOG;
    } else {
      this.resMode = MODE.PARAM;
    }
  }
  renderOutParams() {
    return (
      <div class={this.$style.right}>
        <p class={[this.$style.titleWrap, this.$style.logBox]}>
          <span class={this.$style.title}>返回结果</span>
          <div>
            <span class={this.$style.paramBox}>
              {this.resMode === MODE.PARAM ? '参数模式' : '日志模式'}
            </span>
            <a-icon
              type="swap"
              class={this.$style.logCursor}
              onClick={this.handChangeResMode}
            />
          </div>
        </p>
        <section class={this.$style.resWrap}>
          {this.resMode === MODE.PARAM
            ? this.renderLogMode()
            : this.renderScheme(
                this.filterResList,
                PARAM_STATUS.OUT,
                this.outStore,
              )}
        </section>
      </div>
    );
  }

  renderInParams() {
    let length = Object.keys(this.inFilterStore && this.inFilterStore.data)
      .length;
    return (
      <a-form-model ref={'form'} props={{ model: this.inStore.data }}>
        <a-row class={this.$style.labelHeader}>
          <a-col span={6}>参数名称</a-col>
          <a-col span={4}>数据类型</a-col>
          <a-col span={14}>值</a-col>
        </a-row>
        {this.renderScheme(this.filterList, PARAM_STATUS.IN, this.inStore)}
        <section class={this.$style.btnGroup}>
          {length ? (
            <a-button
              onClick={() => {
                this.requestReset();
              }}
            >
              清空
            </a-button>
          ) : (
            ''
          )}
          {this.hasReady ? (
            <a-button
              type="primary"
              class={this.$style.ml}
              onClick={() => {
                this.handleDebug();
              }}
            >
              {this.actionTxt}
            </a-button>
          ) : (
            ''
          )}
        </section>
      </a-form-model>
    );
  }
  requestReset() {
    Object.keys(this.inFilterStore.data).forEach(key => {
      let str;
      if (
        Object.prototype.toString.call(this.inStore.get(key)) ===
        '[object String]'
      ) {
        str = '';
      } else if (
        Object.prototype.toString.call(this.inStore.get(key)) ===
        '[object Number]'
      ) {
        str = undefined;
      } else if (
        Object.prototype.toString.call(this.inStore.get(key)) ===
        '[object Array]'
      ) {
        str = [];
      } else if (
        Object.prototype.toString.call(this.inStore.get(key)) ===
        '[object Object]'
      ) {
        str = {};
      } else if (
        Object.prototype.toString.call(this.inStore.get(key)) ===
        '[object Boolean]'
      ) {
        str = false;
      } else {
        str = undefined;
      }
      this.inStore.set(key, str);
    });
  }
  async handleDebug() {
    try {
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        await this.$nextTick();
        const valid = await this.$refs.form.validate();
        if (valid) {
          if (this.isAMQP) {
            this.handleWebSocket();
          } else {
            await this.handleRequest();
          }
        }
      } else {
        if (this.isAMQP) {
          this.handleWebSocket();
        } else {
          await this.handleRequest();
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  formatReuestType(method) {
    let type = 'JSON';
    if (method === 'POST') {
      type = this.detail.formatType;
    }
    return type;
  }
  resResult = {};
  async handleRequest() {
    let otherHeader = undefined,
      otherBody = undefined,
      otherQuery = undefined,
      targetUrl = this.requestURL;
    const Method = this.detail.requestMethod;

    // 是否需要前置调用--》预请求（前置接口）
    if (this.trigger.citingPublicParameters === 'PUBLIC_AUTHENTICATION') {
      const result = await preInterface(this.devicePkId, this.apiId);
      const { prefixUrl, prefixData } = prefixAPiFormat(result);

      const body = formatBody({
        list: this.filterListCopy,
        store: this.inStore,
        key: 'id',
      });
      const data = Object.assign(body, prefixData);
      const prefixRes = await prefixApi(prefixUrl, {
        data,
        deviceId: this.devicePkId,
      });
      otherHeader = prefixRes.header;
      otherBody = prefixRes.body;
      otherQuery = prefixRes.query;
      if (otherQuery || prefixData) {
        targetUrl = parseUrlQuery(
          targetUrl,
          Method,
          Object.assign(prefixData || {}, otherQuery || {}),
        );
      }
    }

    const formtType = this.formatReuestType(Method);
    const headers = {
      'api-head': headerOptions(this.detail.requestHeaderParam, otherHeader),
      'api-method': Method,
      'api-url': targetUrl,
    };
    const body = fetchBody(
      Method,
      formtType,
      this.filterListCopy,
      Object.assign(otherBody || {}),
      this.inStore,
    );
    const currentTime = Date.now();
    const result = await debug(
      headers,
      Object.assign(body ?? {}, { devicePkId: this.devicePkId }),
    );
    this.resResult = result;
    this.jsonArray.unshift({
      time: currentTime,
      format: 'YYYY/MM/DD HH:mm:ss',
      result: JSON.stringify(result),
    });

    await addLog({
      callTime: currentTime,
      connectorId: this.apiId,
      inputParams: JSON.stringify({
        'api-head': headerOptions(this.detail.requestHeaderParam, otherHeader),
        'postParams': formatBody({
          list: this.filterListCopy,
          store: this.inStore,
          postType: 'body',
        }),
        'api-url': targetUrl,
      }),
      outputParams: JSON.stringify(result),
    });
  }

  render() {
    return (
      <div>
        {this.apiHasMore && this.apiList.length === 0 ? (
          <div class={this.$style.wrapEmpty}>
            <EmptyContent />
          </div>
        ) : (
          <div class={this.$style.wrap}>
            <ul class={this.$style.listWrap}>
              {this.apiList.map(item => {
                return (
                  <li
                    class={[
                      this.$style.item,
                      this.apiId === item.pkId ? this.$style.active : '',
                    ]}
                    onClick={() => {
                      this.deviceClick(item.pkId);
                    }}
                  >
                    <x-oss-image
                      style={{
                        marginRight: '10px',
                        borderRadius: '32px',
                        width: '32px',
                      }}
                      size="32"
                      oss-path={item.logoUrl}
                      onClick={() => {
                        this.$preview(item.logoUrl, 'image');
                      }}
                    />
                    {item.apiName}
                  </li>
                );
              })}
            </ul>
            <div class={this.$style.center}>
              {this.apiId ? (
                <p class={this.$style.titleWrap}>
                  <span class={this.$style.title}>输入参数</span>
                  （说明：用于控制设备的参数配置，配置前请再三确认设备运行环境！）
                  {this.detail && this.detail.helpDocumentationUrl ? (
                    <span>
                      <a-icon
                        type="question-circle"
                        onClick={() => {
                          window.open(
                            this.detail.helpDocumentationUrl,
                            '_blank',
                          );
                        }}
                      />
                    </span>
                  ) : (
                    ''
                  )}
                </p>
              ) : (
                ''
              )}
              {this.hasRender ? this.renderInParams() : ''}
            </div>
            {this.hasRender ? this.renderOutParams() : ''}
          </div>
        )}
      </div>
    );
  }
}
</script>

<style lang="less" module>
.wrapEmpty {
  height: calc(100vh - 329px);
}
.wrap {
  height: calc(100vh - 329px);
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 2fr 2fr;
}
.center {
  padding: 20px;
  height: calc(100vh - 329px);
  overflow-y: auto;
  border-right: 1px solid var(--border);
}
.right {
  padding: 20px;
  height: calc(100vh - 329px);
  overflow-y: auto;
}
.listWrap {
  width: 100%;
  padding: 20px 0 0 0;
  margin: 0;
  border-right: 1px solid var(--border);
  .item {
    display: flex;
    align-items: center;
    height: 50px;
    position: relative;
    padding: 0 16px;
    cursor: pointer;
    &:hover {
      color: var(--font-active);
      background-color: var(--inline-menu-active);
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-right: 4px solid var(--primary);
      transform: scaleY(0.0001);
      opacity: 0;
      transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
        opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      content: '';
    }

    &.active {
      color: var(--font-active);
      background-color: var(--inline-menu-active);
      &::after {
        transform: scaleY(1);
        opacity: 1;
        transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
          opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }
}
.titleWrap {
  color: var(--font-sub);
  font-size: 14px;
  .title {
    color: var(--font);
    font-size: 16px;
    font-weight: 600;
  }
}
.tooltipIcon {
  margin: 0 5px;
  z-index: 101;
  position: relative;
}
.tooltip {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  max-width: 250px;
}
.boxHtml {
  img {
    display: none;
  }
}
.btnGroup {
  text-align: right;
}
.ml {
  margin-left: 10px;
}
.logBox {
  display: flex;
  justify-content: space-between;
  .logCursor {
    cursor: pointer;
  }
  .paramBox {
    padding: 0 4px;
  }
}
.jsonBox {
  margin-bottom: 20px;
  .jsonString {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-break: anywhere;
  }
  .jsonBorder {
    margin-top: 10px;
    border-top: 1px solid var(--border);
  }
  .copyBox {
    margin-top: -33px;
    text-align: right;
    .copy {
      color: var(--font-active);
      cursor: pointer;
      font-weight: 900;
      display: inline-block;
      padding: 2px 4px;
      background-color: var(--block-bg);
      border-radius: 10px;
    }
  }
  &:last-of-type {
    .jsonBorder {
      border-top: none;
    }
  }
}

.labelHeader {
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: 900;
}
</style>
