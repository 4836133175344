<template>
  <div :class="$style.service">
    <div :class="$style.title">
      <a-select
        style="width: 210px"
        v-model="form.product"
        placeholder="全部设备"
        allowClear
        :getPopupContainer="t => t.parentNode"
        @change="changeProduct"
      >
        <a-select-option
          v-for="item in productList"
          :value="item.pkId"
          :key="item.pkId"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
      <span :class="$style.label">
        说明：购买后同一产品分类下的设备可共享此扩展包的调用额度
      </span>
    </div>
    <div>
      <a-tabs :default-active-key="activeKey" @change="changeTab">
        <a-tab-pane key="CALL_COUNT" tab="调用扩展包">
          <div v-if="activeKey === 'CALL_COUNT'">
            <ExpandList
              :expandList="expendList"
              :loading="loading"
              pageType="CALL_COUNT"
              @refreshList="expendListData"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="STORAGE_TIME" tab="存储扩展包">
          <div v-if="activeKey === 'STORAGE_TIME'">
            <ExpandList
              :expandList="expendList"
              pageType="STORAGE_TIME"
              @refreshList="expendListData"
            />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <div slot="footer" style="display: flex;justify-content: flex-end">
      <a-button type="default" @click="closeModel">取消</a-button>
    </div>
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import { getDeviceProductList, getExpendList } from '@/services/iot-platform';
import ExpandList from '@/views/iot-platform/device-manage/expand-list.vue';

@Component({
  components: {
    ExpandList,
  },
})
export default class ServiceList extends Vue {
  form = {
    product: undefined,
  };
  mounted() {
    this.getProductList();
    this.expendListData();
  }
  productList = [];
  async getProductList() {
    try {
      this.productList = await getDeviceProductList();
    } catch {
      return false;
    }
  }
  expendList = [];
  async expendListData() {
    try {
      this.loading = true;
      const params = {
        expandType: this.activeKey,
      };
      if (this.form.product) {
        params['productId'] = this.form.product;
      }
      if (this.form.product) {
        params['productId'] = this.form.product;
      }
      this.expendList = await getExpendList(params);
      this.loading = false;
    } catch {
      return false;
    }
  }
  changeProduct() {
    this.expendListData();
  }
  loading = false;
  activeKey = 'CALL_COUNT';
  changeTab(activeKey) {
    this.activeKey = activeKey;
    this.expendListData();
  }
  closeModel() {
    this.$emit('close');
  }
}
</script>

<style lang="less" module>
.service {
  .title {
    display: flex;
    align-items: center;
    .label {
      color: var(--font-info);
      margin-left: 10px;
    }
  }
}
</style>
